import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import {
  clear,
  createRefund,
  setClient,
} from '../../../../reducers/activeRefund';
import { fetchRefunds } from '../../../../reducers/refunds';
import { closeModal } from '../../../../reducers/modals';
import { getShiftPayments } from '../../../../reducers/shifts';
import {
  availableCash,
  isOpen as shiftOpenSelector,
} from '../../../../selectors/shifts';
import {
  country as countrySelector,
  shiftsEnabled as shiftsEnabledSelector,
  decimalPrecision,
} from '../../../../selectors/company';
import { stationCashBank as stationCashBankSelector } from '../../../../selectors/app';
import { items, subtotal as subtotalSelector, tipSelector } from '../../../../selectors/activeRefund';
import { handleError } from '../../../../utils/errors';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { transform, getPrevStep, getInvoiceType, getLegalStatus, getTypeRefund } from '../utils';
import { total as totalSelector, isSelectingItems } from '../../../../selectors/activeRefund';
import { calculateActionTimeSelector } from '../../../../selectors/monitoring';
import { DOCUMENT_TYPES } from '../../../../utils/enums/documentTypes';
import useRefundNumerations from './useRefundNumerations';

/**
 * Initial state for the useReducer hook.
 */
const initialState = {
  step: 0,
  editItemIsOpen: false,
  editTipIsOpen: false,
  itemIndex: null,
  method: null,
};

/**
 * Reducer function to manage state transitions.
 *
 * @param {Object} state - Current state.
 * @param {Object} action - Action object containing type and payload.
 * @returns {Object} - Updated state.
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STEP':
      return { ...state, step: action.payload };
    case 'SET_METHOD':
      return { ...state, method: action.payload };
    case 'OPEN_EDIT_ITEM':
      return { ...state, editItemIsOpen: true, itemIndex: action.payload };
    case 'CLOSE_EDIT_ITEM':
      return { ...state, editItemIsOpen: false, itemIndex: null };
    case 'OPEN_EDIT_TIP':
      return { ...state, editTipIsOpen: true };
    case 'CLOSE_EDIT_TIP':
      return { ...state, editTipIsOpen: false };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

/**
 * Custom hook to manage NewRefunds component state and logic.
 *
 * @returns {Object} - Returns state and handler functions.
 */
const useNewRefunds = () => {
  const dispatch = useDispatch();

  // Initialize useReducer for local state management
  const [state, dispatchState] = useReducer(reducer, initialState);
  const { step, editItemIsOpen, editTipIsOpen, itemIndex, method } = state;

  // Selectors
  const total = useSelector(totalSelector);
  const isOpen = useSelector((state) => state.modals?.newRefunds?.isOpen ?? false);
  const subtotal = useSelector(subtotalSelector);
  const itemsInRefund = useSelector(items);
  const stepFromInvoice = useSelector((state) => state.modals?.newRefunds?.params?.step ?? 0);
  const methodFromInvoice = useSelector((state) => state.modals?.newRefunds?.params?.method ?? null);
  const invoiceSelected = useSelector((state) => state.modals?.newRefunds?.params?.invoice ?? null);
  const shiftOpen = useSelector(shiftOpenSelector);
  const shiftsEnabled = useSelector(shiftsEnabledSelector);
  const shiftCash = useSelector(availableCash);
  const stationCashBank = useSelector(stationCashBankSelector);
  const decimal = useSelector(decimalPrecision);
  const isAllItemsPupaleted = useSelector((state) => state.activeRefund?.isAllItemsPupaleted ?? false);
  const isSelectingMode = useSelector(isSelectingItems);
  const tip = useSelector(tipSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);
  const country = useSelector(countrySelector);

  // Utilize the extracted hook for numeration logic
  const { getInvoiceRefundNumeration } = useRefundNumerations(invoiceSelected, country);

  // Initial Refund Values
  const initialRefundMemoized = useMemo(() => ({
    refund: {
      client: invoiceSelected?.client ?? null,
      numeration: getInvoiceRefundNumeration(),
      document: { ...invoiceSelected },
      date: invoiceSelected?.date ?? '',
      step: 1,
      method: {
        value: 'creditToSales',
        label: I18n.get('creditToSales', 'Crédito a ventas'),
      },
    },
  }), [invoiceSelected, getInvoiceRefundNumeration]);

  /**
   * Side Effect: Cleanup on unmount.
   */
  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  /**
   * Side Effect: Set client if invoice has a client ID.
   */
  useEffect(() => {
    if (invoiceSelected?.client?.id) {
      dispatch(setClient(invoiceSelected.client));
    }
  }, [invoiceSelected, dispatch]);

  /**
   * Side Effect: Handle modal open/close state.
   */
  useEffect(() => {
    if (!isOpen) {
      dispatchState({ type: 'RESET' });
      dispatch(clear());
    } else if (shiftsEnabled && shiftOpen) {
      dispatch(getShiftPayments());
    }
  }, [isOpen, shiftsEnabled, shiftOpen, dispatch]);

  /**
   * Side Effect: Update step based on invoice.
   */
  useEffect(() => {
    dispatchState({ type: 'SET_STEP', payload: stepFromInvoice });
  }, [stepFromInvoice]);

  /**
   * Side Effect: Update method based on invoice.
   */
  useEffect(() => {
    dispatchState({ type: 'SET_METHOD', payload: methodFromInvoice });
  }, [methodFromInvoice]);

  // Handlers

  /**
   * Opens the edit item modal for a specific item.
   *
   * @param {number} index - The index of the item to edit.
   */
  const openEditItem = useCallback((index) => {
    dispatchState({ type: 'OPEN_EDIT_ITEM', payload: index });
  }, []);

  /**
   * Closes the edit item modal.
   */
  const closeEditItem = useCallback(() => {
    dispatchState({ type: 'CLOSE_EDIT_ITEM' });
  }, []);

  /**
   * Opens the edit tip modal.
   */
  const openEditTip = useCallback(() => {
    dispatchState({ type: 'OPEN_EDIT_TIP' });
  }, []);

  /**
   * Closes the edit tip modal.
   */
  const closeEditTip = useCallback(() => {
    dispatchState({ type: 'CLOSE_EDIT_TIP' });
  }, []);

  /**
   * Selects a refund method and updates the step.
   *
   * @param {string} newMethod - The selected refund method.
   */
  const selectMethod = useCallback((newMethod) => {
    dispatchState({ type: 'SET_METHOD', payload: newMethod });
    dispatchState({ type: 'SET_STEP', payload: 1 });
  }, []);

  /**
   * Changes the current step in the refund process.
   *
   * @param {number} newStep - The new step to navigate to.
   */
  const changeStep = useCallback((newStep) => {
    dispatchState({ type: 'SET_STEP', payload: newStep });
  }, []);

  /**
   * Closes the refund modal.
   */
  const onCloseModal = useCallback(() => {
    dispatchState({ type: 'RESET' });
    dispatch(clear());
    dispatch(closeModal({ modal: 'newRefunds' }));
  }, [dispatch]);

  /**
   * Submits the refund form.
   *
   * @param {Object} values - The form values.
   * @param {number} tipValue - The tip value.
   */
  const submit = useCallback(async (values, tipValue) => {
    if ((method !== 'positiveBalance' && step !== 4) ||
      (method === 'positiveBalance' && step !== 3)) {
      return;
    }

    const transformedValues = transform(values, { tip: tipValue, decimal, subtotal });

    try {
      dispatch(startAction({ action: 'createRefund' }));
      const canContinue = await dispatch(checkStationValues({ type: 'refund' }));
      if (!canContinue) return;

      let subtitleText = I18n.get('refundCreatedMessage.creditNote', 'Tu nota de crédito se registró con éxito.');
      if (transformedValues.numeration?.documentType === DOCUMENT_TYPES.INCOME_ADJUSTMENT_NOTE) {
        subtitleText = I18n.get('refundCreatedMessage.adjustNote', 'Tu nota de ajuste de ingreso se registró con éxito.');
      }

      const refundCreated = await dispatch(createRefund(transformedValues));
      dispatch(clear());
      dispatch(closeModal({ modal: 'newRefunds' }));
      toast.success({
        title: I18n.get('refundCreated', 'Devolución creada 🎉'),
        subtitle: subtitleText,
        params: {
          position: 'top-right',
        },
      });

      const emissionErrorMessage = refundCreated?.emissionErrorMessage ?? '';
      const invoiceType = getInvoiceType(transformedValues.invoices?.[0] ?? null);
      const creditNoteType = transformedValues.type ?? '';

      dispatch(endAction({ action: 'createRefund' }));

      dispatch(sendNewGTMEvent('pos-refund-created', {
        error: 'no error',
        responseTime: calculateActionTime("createRefund"),
        type: getTypeRefund(method),
        creditNoteType: creditNoteType || '',
        invoiceType: invoiceType || '',
        emissionStatus: transformedValues.numeration?.isElectronic
          ? I18n.get(getLegalStatus(refundCreated))
          : '',
        emissionError: emissionErrorMessage || '',
      }));

      dispatch(fetchRefunds());
    } catch (error) {
      const parseError = handleError(error);
      dispatch(sendNewGTMEvent('pos-refund-created', {
        error: parseError,
      }));
      return formError(
        error,
        I18n.get('createRefundError', 'hubo un error a la hora de crear la devolución')
      );
    }
  }, [method, step, decimal, subtotal, dispatch, calculateActionTime]);

  /**
   * Determines whether to show a notification error based on the method, submit error, and current step.
   *
   * @param {string} methodValue - The refund method value.
   * @param {boolean} submitError - Indicates if there was a submission error.
   * @param {number} currentStep - The current step in the refund process.
   * @returns {boolean} - Whether to show the notification error.
   */
  const getShowNotificationError = useCallback((methodValue, submitError, currentStep) => {
    if (methodValue === 'positiveBalance') {
      return !!submitError && currentStep === 3;
    } else {
      return !!submitError && currentStep === 4;
    }
  }, []);

  return {
    // State
    step,
    editItemIsOpen,
    editTipIsOpen,
    itemIndex,
    method,
    initialRefundMemoized,

    // Selectors
    isOpen,
    total,
    subtotal,
    itemsInRefund,
    stepFromInvoice,
    methodFromInvoice,
    invoiceSelected,
    shiftOpen,
    shiftsEnabled,
    shiftCash,
    stationCashBank,
    decimal,
    isAllItemsPupaleted,
    isSelectingMode,
    tip,

    // Handlers
    openEditItem,
    closeEditItem,
    openEditTip,
    closeEditTip,
    selectMethod,
    changeStep,
    onCloseModal,
    submit,
    getShowNotificationError,
  };
};

export default useNewRefunds;
