import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, set } from 'lodash';
import { useSelector } from 'react-redux'

import { calculateItemsValues, calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import { decimalPrecision, country as countrySelector, companySelector, membershipSelector } from '../../../selectors/company';
import { getMainCurrency } from '../../../selectors/currencies'
import { renderField } from '../fields/V0/Fields';
import KitItem from './modals/KitItem';

import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { Icon } from '@alegradev/smile-ui-react';
import { useFormat } from '../../../hooks/useFormat';

const Kit = ({ values, form, ...props }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector);
  const { decimal, fmt } = useFormat();
  const membership = useSelector(membershipSelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const items = get(values, 'items')
  const itemsValues = !!items
    ? calculateItemsValues({membership,items: items.map(item => !!item
      ? ({
        ...item.item,
        quantity: item.quantity,
        price: get(item, 'item.inventory.unitCost'),
        tax: [],
      }) : null).filter(i => !!i), decimal, country, company})
    : null

  const addItem = () => {
    form.mutators.push("items")
  }

  const removeItem = (callback) => {
    if (get(items, 'length', 0) === 1) {
      form.change('item', get(items, '0.item', null))
      form.change('quantity', get(items, '0.quantity', null))
    }
    callback()
  }

  const openSelectItemModal = index => {
    setSelectedItem({
      item: get(items, `${index}.item`),
      quantity: get(items, `${index}.quantity`),
    })
    setSelectedIndex(index)
    setOpenModal(true)
  }

  return (
    <>
      <div className="p-0 col-12 form-row form-field-array-wrapper">
        <FieldArray name="items">
          {({ fields }) =>
            fields.map((name, index) => {
              const isItemSelected = !!get(items, `${index}.item.name`)
              const itemValue = !!isItemSelected
                ? isDecimalActive
                  ? calculateSingleItemValuesWithDecimals({
                      ...get(items, `${index}.item`),
                      price: get(items, `${index}.item.inventory.unitCost`),
                      quantity: get(values, `items[${index}].quantity`),
                      tax: [],
                  }, decimal)
                : calculateSingleItemValues({
                    ...get(items, `${index}.item`),
                    price: get(items, `${index}.item.inventory.unitCost`),
                    quantity: get(values, `items[${index}].quantity`),
                    tax: [],
                  }, decimal)
                : null;

              return (
                <div
                  key={index} className="pointer w-100"
                  onClick={() => openSelectItemModal(index)}
                >
                  <div className="combo-item-container d-flex aling-items-center form-row">
                    <div className="col-md-4 col-lg-2 d-none d-md-block">
                      <div className="image-container">
                        {!!get(items, `${index}.item.images.length`) && (
                          <img
                            src={get(items, `${index}.item.images.${get(items, `${index}.item.images.length`, 1) - 1}.url`)}
                            alt=""
                            className="w-100 h-100 bg-white"
                          />
                        )}

                        <div className="item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
                          <Icon icon='tag' extraClass="icon-gray icon x2" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8 col-lg-7 item-container d-flex flex-column justify-content-center">
                      <p className={`h4 mb-1 ${!isItemSelected ? 'text-subtitle' : ''}`}>
                        {!!isItemSelected
                          ? get(items, `${index}.item.name`)
                          : I18n.get('selectProduct', 'seleccionar producto')
                        }
                      </p>
                      <p className="h5 m-0 text-subtitle">
                        {!!isItemSelected
                          ? !!itemValue ? itemValue.total.toFormat(decimal, fmt) : 0
                          : I18n.get('selectProductHelp', 'Agrega aquí los productos de tu combo')
                        }
                      </p>
                    </div>

                    <div className="col-sm-4 col-lg-3 col-md-12 form-row align-items-end">
                      <Field
                        name={`${name}.quantity`}
                        component={renderField}
                        className="col-10"
                        type="number"
                        label={capitalize(I18n.get('quantity', 'cantidad'))}
                        onChange={e => kitMaxDecimals(e, `${name}.quantity`, 4, values, country, isDecimalActive)}
                      />
                      <button
                        type="button"
                        className="btn button-transparent col-2 mb-3"
                        disabled={fields.length <= 1}
                        style={{ height: '3.6rem', outline: 'none', boxShadow: 'none', display: values?.items?.length > 1 ? 'inline' : 'none' }}
                        onClick={(event) => {
                          removeItem(() => fields.remove(index))
                          event.stopPropagation()
                        }}
                      >
                        <Icon icon='x' extraClass="icon-secondary" />
                      </button>
                    </div>
                  </div>

                </div>
              )
            })
          }
        </FieldArray>


        <div className="d-flex justify-content-between align-items-center w-100">
          <button
            type="button"
            className="btn btn-secondary primary mb-3 mr-3"
            style={{ border: "none", outline: "none", boxShadow: "none" }}
            onClick={() => addItem()}
          >
            <Icon icon='plus' extraClass="icon icon-primary mr-2" />
            {capitalize(I18n.get('addItem', 'agregar producto'))}
          </button>

          <p className="text-capitalize-first h4 text-subtitle">
            {I18n.get('totalCost', 'costo total')}
            {': '}
            {!!itemsValues ? itemsValues.total.toFormat(decimal, fmt) : 0}
          </p>
        </div>
      </div>

      <KitItem
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        item={selectedItem}
        form={form}
        values={values}
        index={selectedIndex}
      />
    </>
  )
}

export function kitMaxDecimals(e, key, decimals = 6, values, country, isActive) {
  if (!isActive)
    return e.target.value;

  const newButton = e.nativeEvent.data;
  set(values, key, !get(values, key) ? "" : get(values, key));
  e.target.value = !e.target.value ? "" : e.target.value;

  if (newButton === '.') {
    if (!e.target.value.slice(0, -1).includes('.')) {
      return e.target.value;
    } else {
      e.target.value = values[key];
      return get(values, key);
    }
  }

  if (!isNaN(newButton)) {
    if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
      return e.target.value;
    } else {
      e.target.value = get(values, key);
      return get(values, key);
    }
  }

  if (isNaN(newButton)) {
    e.target.value = get(values, key);
    return get(values, key);
  }
}

export default Kit;