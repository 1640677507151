import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';

import { renderField } from '../../fields/V0/Fields';
import { hasPermissionTo } from '../../../../selectors/auth';
import { useSelector } from 'react-redux';

const colombiaName = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const kindOfPerson = get(props, 'values.kindOfPerson.key', '');
  const completeName = (identification === 'NIT' && kindOfPerson === 'PERSON_ENTITY')
    || (!!props.isElectronic && identification !== 'NIT');
  const isElectronic = get(props, 'isElectronic', false);

  const labelName = () => {
    if (!isElectronic || identification !== 'NIT') {
      return capitalize(I18n.get('name', 'Nombre'));
    }
    if (kindOfPerson === 'LEGAL_ENTITY') {
      return capitalize(I18n.get('socialRazon', 'razón social'));
    }
    return capitalize(I18n.get('name', 'Nombre'));
  }

  return (
    <>
      <Field
        name="firstName"
        className={identification === 'NIT' ? 'col-md-6' : 'col-12'}
        component={renderField}
        data-testid="client-first-name-input"
        type="text"
        label={labelName()}
        required
      />

      {completeName && (
        <>
          <Field
            name="secondName"
            className="col-md-6"
            component={renderField}
            data-testid="client-second-name-input"
            type="text"
            label={capitalize(I18n.get('secondName', 'Segundo Nombre'))}
          />
          <Field
            name="lastName"
            className={identification === 'NIT' ? 'col-md-6' : 'col-12'}
            component={renderField}
            data-testid="client-last-name-input"
            type="text"
            label={capitalize(I18n.get('lastName', 'Apellidos'))}
            required
          />
        </>
      )
      }

      {
        isElectronic && (identification === 'NIT') && (
          <Field
            name="tradeName"
            className="col-12"
            component={renderField}
            data-testid="client-name-input"
            type="text"
            label={capitalize(I18n.get('tradeName', 'Nombre'))}
          />
        )
      }
    </>
  )
}

const peruName = ({can}) => {
  return (
    <Field
      name="firstName"
      className="col-md-6"
      component={renderField}
      data-testid="client-first-name-input"
      type="text"
      placeholder={capitalize(I18n.get('socialRazon', 'razón social'))}
      label={capitalize(I18n.get('socialRazon', 'razón social'))}
      disabled={!can('edit', 'company')}
      required
    />
  )
}

const costaRicaName = ({can}) => (
  <Field
    name="firstName"
    className="col-12"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={capitalize(I18n.get('name', 'Nombre'))}
    disabled={!can('edit', 'company')}
    required
  />
)

const argentinaName = ({can}) => (
  <Field
    name="firstName"
    className="col-md-6"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={capitalize(I18n.get('name', 'Nombre'))}
    disabled={!can('edit', 'company')}
    required
  />
)

const panamaName = ({values, can}) => {
  const kindOfPerson = get(values, 'kindOfPerson.key', '');
  const completeName = kindOfPerson === 'PERSON_ENTITY';

  return (
    <>
      <Field
        name="firstName"
        className="col-md-6"
        component={renderField}
        data-testid="client-first-name-input"
        type="text"
        label={completeName
          ? capitalize(I18n.get('firstName', 'Nombre'))
          : capitalize(I18n.get('socialRazon', 'razón social'))
        }
        required
      />

      {completeName && (
        <>
          <Field
            name="secondName"
            className="col-md-6"
            component={renderField}
            data-testid="client-second-name-input"
            type="text"
            label={capitalize(I18n.get('secondName', 'Segundo Nombre'))}
          />
          <Field
            name="lastName"
            className="col-md-6"
            component={renderField}
            data-testid="client-last-name-input"
            type="text"
            label={capitalize(I18n.get('lastName', 'Apellidos'))}
            required
          />
        </>
      )
      }

      <Field
        name="tradeName"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('tradeName', 'Nombre comercial')}
      />

    </>
  )
}

const spaintName = props => (
  <>
    <Field
      name="firstName"
      className="col-12"
      component={renderField}
      data-testid="client-name-input"
      type="text"
      label={capitalize(I18n.get('taxName', 'Nombre'))}
      required
    />
    <Field
      name="tradeName"
      className="col-12"
      component={renderField}
      data-testid="client-name-input"
      type="text"
      label={capitalize(I18n.get('tradeName', 'Nombre'))}
    />
  </>

)

const mexicoName = ({can}) => {
  const fieldToUpperCase = (e) => {
    e.preventDefault();
    if (!props.isElectronic)
      return e.target.value
    return String(e.target.value).toUpperCase();
  }

  return (
    <>
      <Field
        name="firstName"
        className="col-md-6"
        component={renderField}
        data-testid="client-name-input"
        type="text"
        onChange={fieldToUpperCase}
        label={capitalize(I18n.get('name', 'Nombre'))}
        disabled={!can('edit', 'company')}
        required
      />

      <Field
        name="tradeName"
        className="col-md-6"
        component={renderField}
        type="text"
        disabled={!can('edit', 'company')}
        label={I18n.get('tradeName', 'Nombre comercial')}
      />
    </>
  )
}

const defaultName = ({can}) => (
  <Field
    name="firstName"
    className="col-12"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={capitalize(I18n.get('name', 'Nombre'))}
    disabled={!can('edit', 'company')}
    required
  />
)

const renderName = props => {
  const can = useSelector(hasPermissionTo);
  switch (props.country) {
    case 'colombia':
      return colombiaName({...props, can});

    case 'costaRica':
      return costaRicaName({ ...props, can });

    case 'peru':
      return peruName({ ...props, can });

    case 'argentina':
      return argentinaName({ ...props, can });

    case 'panama':
      return panamaName({ ...props, can });

    case 'spain':
      return spaintName({ ...props, can });

    case 'mexico':
      return mexicoName({ ...props, can });

    default:
      return defaultName({ ...props, can });
  }
}

export default renderName;