import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from '../../../reducers/activeInvoice';
import { renderErrors } from '../../home/NewActiveInvoice/utils';
import { country as countrySelector } from '../../../selectors/company';
import {
  station as stationSelector,
  electronicInvoicing,
} from '../../../selectors/app';
import { numeration as numerationSelector } from '../../../selectors/activeInvoice';
import { Icon, Tooltip } from '@alegradev/smile-ui-react';

const ItemInfo = ({ item, index, handleClose }) => {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const numeration = useSelector(numerationSelector);
  const station = useSelector(stationSelector);
  const isElectronic = useSelector(electronicInvoicing(numeration));

  const handleDelete = () => {
    dispatch(removeItem(index));
    handleClose();
  };

  const errors = renderErrors({ item, country, station, isElectronic });

  return (
    <>
      <div className='item-info p-3 flex justify-content-between items-center'>
        <div className='flex items-center'>
          <div className='item-info__image ml-4 mt-4 d-flex align-items-center'>
            {item.images ? (
              <img src={item.images[0].url} alt={item.name} />
            ) : (
              <Icon icon='tag' extraClass='icon-gray icon x2' />
            )}
          </div>
          <div className='item-info__detail mt-4 ml-4'>
            <p className='item-info__detail-product-type'>
              {I18n.get(
                item.type === 'service'
                  ? 'service'
                  : item.type === 'kit'
                    ? 'kit'
                    : 'product'
              )}
            </p>
            <p className='item-info__detail-product-name'>{item.name}</p>
            {(!!get(item, 'reference.reference') ||
              !!get(item, 'reference')) && (
              <p className='item-info__detail-product-reference'>
                {get(item, 'reference.reference') || get(item, 'reference')}
              </p>
            )}
          </div>
        </div>
        <Tooltip tooltipActive={true} placement='left' overlay='Quitar'>
          <div className='item-info__delete' onClick={handleDelete}>
            <Icon icon='trash' size='medium' />
          </div>
        </Tooltip>
      </div>
      {errors && <div className='pl-4'>{errors}</div>}
      <div className='mx-3 mt-1'>
        <div
          className='mx-4'
          style={{ background: '#E2E8F0', height: '1px' }}
        />
      </div>
    </>
  );
};

export default ItemInfo;
