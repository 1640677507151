import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field, useForm } from 'react-final-form';
import { capitalize } from 'lodash';

import { Grid, Button } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';

const PanamaInfo = () => {
  const { Col } = Grid;
  const { change } = useForm();
  const filterOnlyNumbers = (e, numberLength, splitPosition) => {
    let value = e.target.value.replace(/[^0-9]+/g, '');
    if (value.length >= splitPosition + 1 && value.length <= numberLength + 1) {
      value =
        value.substring(0, splitPosition) +
        '-' +
        value.substring(splitPosition);
    }
    return value.length <= numberLength + 1
      ? value
      : value.slice(0, numberLength + 1);
  };
  return (
    <>
      <Col xs={6}>
        <Field
          name='email'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('e-email', 'Correo Electrónico'))}
        />
      </Col>
      <Col xs={6}>
        <Field
          name='phonePrimary'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('phone', 'Teléfono'))}
          onChange={(e) => change('phonePrimary', filterOnlyNumbers(e, 7, 3))}
          placeholder={I18n.get('phone-placeholder', '_ _ _ - _ _ _ _')}
        />
      </Col>
      <Col xs={6}>
        <Field
          name='mobile'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('mobilePhone', 'Celular'))}
          onChange={(e) => change('mobile', filterOnlyNumbers(e, 8, 4))}
          placeholder={I18n.get('phone-placeholder', '_ _ _ _ - _ _ _ _')}
        />
      </Col>
    </>
  );
};

const DefaultInfo = (props) => {
  const { Col, Row, Wrapper } = Grid;
  const [extraPhone, setExtraPhone] = useState(false);

  useEffect(() => {
    if (props?.values?.phonePrimary) {
      setExtraPhone(true);
    }
  }, []);

  return (
    <>
      {props.country !== 'colombia' && (
        <Col xs={6}  sm={12}>
          <Field
            name='email'
            component={renderField}
            type='text'
            label={capitalize(I18n.get('email', 'Correo'))}
          />
        </Col>
      )}

      {!extraPhone && (
        <Col xs={6}  sm={12}>
          <Row>
            <Col xs={10}>
              <Field
                name='phonePrimary'
                component={renderField}
                type='text'
                label={capitalize(I18n.get('phone', 'Teléfono'))}
              />
            </Col>

            <Col xs={1}>
              <Wrapper fullHeight align={{ lg: 'end' }}>
                <Button
                  type='button'
                  emphasis='text'
                  onlyIcon='plus'
                  onClick={() => setExtraPhone(true)}
                />
              </Wrapper>
            </Col>
          </Row>
        </Col>
      )}

      {!!extraPhone && (
        <>
          <Col xs={6}>
            <Field
               name='phonePrimary'
              component={renderField}
              type='text'
              label={capitalize(I18n.get('phone', 'Teléfono'))}
              
            />
          </Col>

          <Col xs={6}>
            <Row>
              <Col xs={10}>
                <Field
                  name='mobile'
                  component={renderField}
                  type='text'
                  label={capitalize(I18n.get('mobilePhone', 'Celular'))}
                />
              </Col>

              <Col xs={1}>
                <Wrapper fullHeight align={{ lg: 'end' }}>
                  <Button
                    type='button'
                    emphasis='text'
                    onlyIcon='minus'
                    onClick={() => {
                      props.form.change('mobile', null);
                      setExtraPhone(false);
                    }}
                  />
                </Wrapper>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

const RenderInfo = (props) => {
  const country = props.country;

  switch (country) {
    case 'panama':
      return <PanamaInfo {...props} />;
    default:
      return <DefaultInfo {...props} />;
  }
};

export default RenderInfo;
