import { COUNTRIES } from '../utils/enums/countries';
import db from './database';
import _, { deburr, isNull, isEmpty, isUndefined } from 'lodash';

export const bulkPut = async (contacts, callback) => {
  await db.contacts.bulkPut(contacts);

  if (!!callback) callback();
};

export const put = async (contact, callback) => {
  await db.contacts.put({ ...contact });
  if (!!callback) callback();
};

export const total = () => db.contacts.count();

export const totalOffline = () =>
  db.contacts.filter((client) => !!client.offline).count();

export const get = async ({ start, limit }) => {
  try {
    return await db.contacts.offset(start).limit(limit).toArray();
  } catch {
    return [];
  }
};

export const getAll = async () => {
  try {
    return await db.contacts.toArray();
  } catch {
    return [];
  }
};

export const getAllOffline = async () => {
  try {
    return await db.contacts.filter((client) => !!client.offline).toArray();
  } catch {
    return [];
  }
};

export const getByOfflineId = async (offlineId) => {
  try {
    if (offlineId) {
      const contacts = await db.contacts
        .filter((contact) => contact.offlineId === offlineId)
        .toArray();
      return contacts.length > 0 ? contacts[0] : {};
    }

    return {};
  } catch {
    return {};
  }
};

export const getClientById = async (clientId) => {
  try {
    if (clientId) {
      const client =
        (await db.contacts.get(Number(clientId))) ||
        (await db.contacts.get(clientId.toString()));
      return client || {};
    }
  } catch {
    return {};
  }
};

const hasIdentification = ({ identification }, text) => {
  if (!identification) return false;
  return identification.toString().trim().toLowerCase().includes(text?.trim());
};

export const filter = async ({ text, limit }) => {
  try {
    const deburredText = deburr(text.toLowerCase());

    return await db.contacts
      .orderBy('name')
      .filter((contact) => {
        const contactName = deburr(contact.name.toLowerCase().trim());

        const isNameMatch = contactName.includes(deburredText);
        const isIdentificationMatch = hasIdentification(contact, deburredText);

        return contact.status === 'active' && (isNameMatch || isIdentificationMatch);
      })
      .limit(limit)
      .toArray();
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const clear = () => db.contacts.clear();

export const update = async (id, changes, callback) => {
  await db.contacts.update(id, changes);
  if (callback) callback();
};

export const remove = (id) => db.contacts.delete(id);

export const getPOSClient = (country, name) => {
  switch (country) {
    case 'costaRica':
      return db.contacts
        .where('[name+identification]')
        .equals(['Cliente de contado', '100000000'])
        .first();
    case 'peru':
      return db.contacts
        .where('[name+identification]')
        .equals(['Cliente varios', '00000000'])
        .first();
    case 'colombia':
      return db.contacts
        .where('[name+identification]')
        .equals(['Consumidor final', '222222222222'])
        .first();
    case 'mexico':
      return db.contacts
        .where('[name+identification]')
        .equals([name || 'Público en General', 'XAXX010101000'])
        .first();
    case 'argentina':
      return db.contacts
        .where('[name+identification]')
        .equals(['Consumidor final', '1'])
        .first();
    case 'republicaDominicana':
      return db.contacts.where('name').equals('Consumidor final').first();
    case 'panama':
      return db.contacts.where('name').equals('Consumidor final').first();
    case 'spain':
      return db.contacts.where('name').equals('Público en General').first();
    default:
      return db.contacts.where('name').equals('POS').first();
  }
};

export const bulkDelete = (keys) => db.contacts.bulkDelete(keys);

export const searchContactByIdentification = (identification, country) => {
  if (country === COUNTRIES.MEXICO) {
    return db.contacts
      .filter((contact) => {
        const eqNumber = identification === _.get(contact, 'identification', null);
        const eqFiscalId = identification === _.get(contact, 'fiscalId', null);
        return eqNumber || eqFiscalId;
      })
      .first();
  }
  return db.contacts
    .filter((contact) => {
      const eqNumber =
        identification === _.get(contact, 'identificationObject.number', null);
      return eqNumber;
    })
    .first();
};

export const searchIdentification = (identification, country) => {
  try {
    if (country === COUNTRIES.ARGENTINA) {
      if (
        _.get(identification, 'type', null) &&
        _.get(identification, 'number', null)
      ) {
        return db.contacts
          .filter((contact) => {
            const eqType =
              _.get(identification, 'type') ===
              _.get(contact, 'identificationObject.type', null);
            const eqNumber =
              _.get(identification, 'number') ===
              _.get(contact, 'identificationObject.number', null);
            return eqType && eqNumber;
          })
          .first();
      }
    } else if (
      [
        COUNTRIES.COSTA_RICA,
        COUNTRIES.PANAMA,
        COUNTRIES.REPUBLICA_DOMINICANA,
        COUNTRIES.COLOMBIA,
        COUNTRIES.PERU,
      ].includes(country)
    ) {
      if (_.get(identification, 'number', null)) {
        return db.contacts
          .filter((contact) => {
            const eqNumber =
              _.get(identification, 'number') ===
              _.get(contact, 'identificationObject.number', null);
            return eqNumber;
          })
          .first();
      }
    } else if (country === COUNTRIES.PERU) {
      if (
        _.get(identification, 'type', null) &&
        _.get(identification, 'number', null)
      ) {
        return db.contacts
          .filter((contact) => {
            const eqType =
              _.get(identification, 'type') ===
              (_.get(contact, 'identification.type', null) ||
                _.get(contact, 'identificationObject.type', null));
            const eqNumber =
              _.get(identification, 'number') ===
              (_.get(contact, 'identification.number', null) ||
                _.get(contact, 'identificationObject.number', null));
            return eqType && eqNumber;
          })
          .first();
      }
    } else {
      if (!!identification) {
        return db.contacts
          .filter((contact) => {
            return identification === _.get(contact, 'identification', null);
          })
          .first();
      }
    }
    return Promise.resolve();
  } catch {
    return Promise.resolve();
  }
};
