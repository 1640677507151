/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($channel: String!) {
    onUpdateUser(channel: $channel) {
      channel
      id
      idGlobal
      company {
        id
        name
        identification
        phone
        website
        economicActivity
        economicActivities {
          id
          code
          value
          main
        }
        email
        regime
        ivaCondition
        applicationVersion
        registryDate
        timezone
        profile
        decimalPrecision
        multitax
        isActiveNoIvaDay
        showNoIvaDaysOnItems
        legalRepresentative {
          name
          identification
          email
        }
        mandateSignatureUrl
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        currency {
          code
          symbol
          exchangeRate
          name
          status
        }
        multicurrency
        decimalSeparator
        invoicePreferences {
          defaultAnotation
          defaultTermsAndConditions
        }
        logo
        origin
        kindOfPerson
        nameObject {
          firstName
          lastName
        }
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        seenTutorialWizard
        firstPOSInvoice
        subscription
        settings {
          canStampInvoices
          electronicInvoicing
          canCreateAiuInvoice
          canCreateExportInvoice
          showSaleTickets
          isActiveAutomationElectronicInvoice
          automationElectronicInvoiceIdResolution
          barcode
        }
        localSettings {
          idCategoryDefaultIncome
          idCategoryDefaultExpense
          shiftsEnabled
          shiftsConfigured
          multicurrencyEnabled
          automationFEEnabled
          showReminder5UVT
          ticketItemsDescription
          ticketCompanyLogo
          ticketUnitPrice
          printFormat
          printTemplate
          printAlignment
          printRightMargin
          printLeftMargin
          printItemFullLine
          showWompiIntegrationAvailable
          showNumerationBlockNotification
          seenTutorialWizardPOS
        }
        providers
        numberIibb
        conditionIibb
        activityStartDate
        monthIncome {
          income
          invoices
        }
        numberIibb
        conditionIibb
        activityStartDate
        dateFormat
        employeesNumber
        sector
        userNotifications {
          notifications
        }
      }
      hubspot {
        id
        cookieIdentify
        cookieMessages
        token
        tokenExpiration
      }
      membership {
        planName
        planKeyword
        dateStart
        dateEnd
        nextPaymentDate
        payRetryDate
        isPaid
      }
      permissions
      name
      lastName
      email
      role
      status
      position
      language
      seenWelcome
      seenNoIVADayNotification
      seenColombianNormChangeNotification
    }
  }
`;
export const onCreateStation = /* GraphQL */ `
  subscription OnCreateStation($channel: String!) {
    onCreateStation(channel: $channel) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idInvoiceNumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const onUpdateStation = /* GraphQL */ `
  subscription OnUpdateStation($channel: String!) {
    onUpdateStation(channel: $channel) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idInvoiceNumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const onDeleteStation = /* GraphQL */ `
  subscription OnDeleteStation($channel: String!) {
    onDeleteStation(channel: $channel) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idInvoiceNumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const onOpenShift = /* GraphQL */ `
  subscription OnOpenShift($channel: String!) {
    onOpenShift(channel: $channel) {
      channel
      id
      idLocal
      idCompany
      idStation
      start
      end
      startingAmount
      transfer
      refunds
      cash
      debit
      credit
      inPayment
      outPayment
      observations
    }
  }
`;
export const onCloseShift = /* GraphQL */ `
  subscription OnCloseShift($channel: String!) {
    onCloseShift(channel: $channel) {
      channel
      id
      idLocal
      idCompany
      idStation
      start
      end
      startingAmount
      transfer
      refunds
      cash
      debit
      credit
      inPayment
      outPayment
      observations
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice($channel: String!) {
    onCreateInvoice(channel: $channel) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
    }
  }
`;
export const onCancelInvoice = /* GraphQL */ `
  subscription OnCancelInvoice($channel: String!) {
    onCancelInvoice(channel: $channel) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
    }
  }
`;
export const onCreateRefund = /* GraphQL */ `
  subscription OnCreateRefund($channel: String!) {
    onCreateRefund(channel: $channel) {
      channel
      id
      idLocal
      idStation
      idShift
      anotation
      balance
      barCodeContent
      cause
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      dateTime
      dueDate
      invoices {
        id
        prefix
        number
        date
        dueDate
        amount
        total
        balance
      }
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      refunds {
        id
        number
        date
        account {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        amount
        observations
      }
      saleConcept
      saleCondition
      saleType
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalApplied
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthIncome {
        income
        invoices
      }
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment($channel: String!) {
    onCreatePayment(channel: $channel) {
      channel
      id
      idStation
      idLocal
      idShift
      number
      date
      amount
      bankAccount {
        id
        name
        description
        initialBalance
        initialBalanceDate
        number
        status
        type
      }
      categories {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      paymentMethod
      observations
      anotation
      status
      type
    }
  }
`;
export const onCreatePendingInvoice = /* GraphQL */ `
  subscription OnCreatePendingInvoice($channel: String!) {
    onCreatePendingInvoice(channel: $channel) {
      channel
      id
      name
      invoice
      idCompany
      idStation
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePendingInvoice = /* GraphQL */ `
  subscription OnDeletePendingInvoice($channel: String!) {
    onDeletePendingInvoice(channel: $channel) {
      channel
      id
      name
      invoice
      idCompany
      idStation
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePendingInvoice = /* GraphQL */ `
  subscription OnUpdatePendingInvoice($channel: String!) {
    onUpdatePendingInvoice(channel: $channel) {
      channel
      id
      name
      invoice
      idCompany
      idStation
      createdAt
      updatedAt
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem($channel: String!) {
    onCreateItem(channel: $channel) {
      channel
      id
      name
      description
      reference
      price {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      tax {
        id
        name
        percentage
        description
        type
        status
      }
      category {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      inventory {
        unit
        availableQuantity
        unitCost
        initialQuantity
        warehouses {
          id
          name
          observations
          isDefault
          address
          status
          initialQuantity
          availableQuantity
          minQuantity
          maxQuantity
          negativeSale
        }
      }
      status
      type
      itemType
      productKey
      tariffHeading
      brand
      model
      itemCategory {
        channel
        id
        name
        description
        status
        image {
          id
          name
          url
        }
      }
      hasNoIvaDays
      customFields {
        id
        name
        key
        value
        description
        defaultValue
        resourceType
        status
        type
        settings
        options
      }
      images {
        id
        name
        url
      }
      kitWarehouse {
        id
        name
        observations
        isDefault
        address
        status
        initialQuantity
        availableQuantity
        minQuantity
        maxQuantity
        negativeSale
      }
      subitems
      variantAttributes
      itemVariants
      predial
      taxCondition {
        value
        description
      }
    }
  }
`;
export const onCreateItemNotify = /* GraphQL */ `
  subscription OnCreateItemNotify($channel: String!) {
    onCreateItemNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onUpdateItemNotify = /* GraphQL */ `
  subscription OnUpdateItemNotify($channel: String!) {
    onUpdateItemNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onDeleteItemNotify = /* GraphQL */ `
  subscription OnDeleteItemNotify($channel: String!) {
    onDeleteItemNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onItemQuantityChangeNotify = /* GraphQL */ `
  subscription onItemQuantityChangeNotify($channel: String!) {
    onItemQuantityChangeNotify(channel: $channel) {
      channel
      id
      quantity
      warehouse
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($channel: String!) {
    onCreateClient(channel: $channel) {
      channel
      id
      address {
        address
        city
        colony
        country
        department
        description
        district
        township
        locality
        municipality
        neighborhood
        postalCode
        province
        state
        street
        ubigeoCode
        urbanization
        zipCode
        exteriorNumber
        interiorNumber
      }
      email
      fax
      identification
      identificationObject {
        type
        number
        kindOfPerson
        dv
      }
      kindOfPerson
      mobile
      name
      nameObject {
        firstName
        lastName
      }
      phonePrimary
      phoneSecondary
      regime
      ivaCondition
      observations
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      settings {
        sendElectronicDocuments
      }
      statementAttached
      status
      term {
        channel
        id
        days
        name
      }
    }
  }
`;
export const onCreateClientNotify = /* GraphQL */ `
  subscription OnCreateClientNotify($channel: String!) {
    onCreateClientNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onUpdateClientNotify = /* GraphQL */ `
  subscription OnUpdateClientNotify($channel: String!) {
    onUpdateClientNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onDeleteClientNotify = /* GraphQL */ `
  subscription OnDeleteClientNotify($channel: String!) {
    onDeleteClientNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onCreateItemCategory = /* GraphQL */ `
  subscription OnCreateItemCategory($channel: String!) {
    onCreateItemCategory(channel: $channel) {
      channel
      id
      name
      description
      status
      image {
        id
        name
        url
      }
    }
  }
`;
export const onUpdateItemCategory = /* GraphQL */ `
  subscription OnUpdateItemCategory($channel: String!) {
    onUpdateItemCategory(channel: $channel) {
      channel
      id
      name
      description
      status
      image {
        id
        name
        url
      }
    }
  }
`;
export const onDeleteItemCategory = /* GraphQL */ `
  subscription OnDeleteItemCategory($channel: String!) {
    onDeleteItemCategory(channel: $channel) {
      channel
      id
      name
      description
      status
      image {
        id
        name
        url
      }
    }
  }
`;
export const onCreatePriceList = /* GraphQL */ `
  subscription OnCreatePriceList($channel: String!) {
    onCreatePriceList(channel: $channel) {
      channel
      id
      idPriceList
      name
      price
      type
      main
      status
      percentage
    }
  }
`;
export const onUpdatePriceList = /* GraphQL */ `
  subscription OnUpdatePriceList($channel: String!) {
    onUpdatePriceList(channel: $channel) {
      channel
      id
      idPriceList
      name
      price
      type
      main
      status
      percentage
    }
  }
`;
export const onDeletePriceList = /* GraphQL */ `
  subscription OnDeletePriceList($channel: String!) {
    onDeletePriceList(channel: $channel) {
      channel
      id
      idPriceList
      name
      price
      type
      main
      status
      percentage
    }
  }
`;
export const onCreateWarehouse = /* GraphQL */ `
  subscription OnCreateWarehouse($channel: String!) {
    onCreateWarehouse(channel: $channel) {
      channel
      id
      isDefault
      name
      observations
      status
      costCenter {
        id
        code
        name
        description
        status
      }
      address
    }
  }
`;
export const onUpdateWarehouse = /* GraphQL */ `
  subscription OnUpdateWarehouse($channel: String!) {
    onUpdateWarehouse(channel: $channel) {
      channel
      id
      isDefault
      name
      observations
      status
      costCenter {
        id
        code
        name
        description
        status
      }
      address
    }
  }
`;
export const onDeleteWarehouse = /* GraphQL */ `
  subscription OnDeleteWarehouse($channel: String!) {
    onDeleteWarehouse(channel: $channel) {
      channel
      id
      isDefault
      name
      observations
      status
      costCenter {
        id
        code
        name
        description
        status
      }
      address
    }
  }
`;
export const onCreateWarehouseNotify = /* GraphQL */ `
  subscription OnCreateWarehouseNotify($channel: String!) {
    onCreateWarehouseNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onUpdateWarehouseNotify = /* GraphQL */ `
  subscription OnUpdateWarehouseNotify($channel: String!) {
    onUpdateWarehouseNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onDeleteWarehouseNotify = /* GraphQL */ `
  subscription OnDeleteWarehouseNotify($channel: String!) {
    onDeleteWarehouseNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onCreatePaymentTerm = /* GraphQL */ `
  subscription OnCreatePaymentTerm($channel: String!) {
    onCreatePaymentTerm(channel: $channel) {
      channel
      id
      days
      name
    }
  }
`;
export const onCreateSeller = /* GraphQL */ `
  subscription OnCreateSeller($channel: String!) {
    onCreateSeller(channel: $channel) {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const onUpdateSeller = /* GraphQL */ `
  subscription OnUpdateSeller($channel: String!) {
    onUpdateSeller(channel: $channel) {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const onDeleteSeller = /* GraphQL */ `
  subscription OnDeleteSeller($channel: String!) {
    onDeleteSeller(channel: $channel) {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const onCreateProviderPayment = /* GraphQL */ `
  subscription OnCreateProviderPayment($channel: String!) {
    onCreateProviderPayment(channel: $channel) {
      channel
      id
      external_id
      merchant_provider_id
      terminal_id
      amount
      created_at
      metadata
      origen
      provider
      requestBody
      resource
      resource_id
      resource_type
      response_body
      state
      updated_at
      code
      type
    }
  }
`;
export const onReverseProviderPayment = /* GraphQL */ `
  subscription OnReverseProviderPayment($channel: String!) {
    onReverseProviderPayment(channel: $channel) {
      channel
      id
      external_id
      merchant_provider_id
      terminal_id
      amount
      created_at
      metadata
      origen
      provider
      requestBody
      resource
      resource_id
      resource_type
      response_body
      state
      updated_at
      code
      type
    }
  }
`;
export const onCreateNumberTemplateNotify = /* GraphQL */ `
  subscription OnCreateNumberTemplateNotify($channel: String!) {
    onCreateNumberTemplateNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onUpdateNumberTemplateNotify = /* GraphQL */ `
  subscription OnUpdateNumberTemplateNotify($channel: String!) {
    onUpdateNumberTemplateNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onDeleteNumberTemplateNotify = /* GraphQL */ `
  subscription OnDeleteNumberTemplateNotify($channel: String!) {
    onDeleteNumberTemplateNotify(channel: $channel) {
      channel
      id
    }
  }
`;
export const onCreateCreditNoteNotify = /* GraphQL */ `
  subscription OnCreateCreditNoteNotify($channel: String!) {
    onCreateCreditNoteNotify(channel: $channel) {
      channel
      id
      invoices
      income
    }
  }
`;
export const onDeclinedPaymentNotify = /* GraphQL */ `
  subscription OnDeclinedPaymentNotify($channel: String!) {
    onDeclinedPaymentNotify(channel: $channel) {
      channel
      attempt
      name
      title
      text
      action
      actionUrl
      last
    }
  }
`;
export const onDismissPopupNotification = /* GraphQL */ `
  subscription OnDismissPopupNotification($channel: String!) {
    onDismissPopupNotification(channel: $channel) {
      channel
    }
  }
`;

export const onCreateCustomProviderNotify = /* GraphQL */ `
  subscription OnCreateCustomProviderNotify($channel: String!) {
    onCreateCustomProviderNotify(channel: $channel) {
      channel
      id
      idLocal
      name
      instructions
      merchant_id
      logo {
        id
        url
      }
      qr {
        id
        url
      }
      status
  }
}`;

export const onCreatePriceListNotify = /* GraphQL */ `
  subscription OnCreatePriceListNotify($channel: String!) {
    onCreatePriceListNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onUpdateCustomProviderNotify = /* GraphQL */ `
  subscription OnUpdateCustomProviderNotify($channel: String!) {
    onUpdateCustomProviderNotify(channel: $channel) {
      channel
      id
      idLocal
      name
      instructions
      merchant_id
      logo {
        id
        url
      }
      qr {
        id
        url
      }
      status
  }
}`;

export const onUpdatePriceListNotify = /* GraphQL */ `
  subscription OnUpdatePriceListNotify($channel: String!) {
    onUpdatePriceListNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onDeletePriceListNotify = /* GraphQL */ `
  subscription OnDeletePriceListNotify($channel: String!) {
    onDeletePriceListNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onCreateItemCategoryNotify = /* GraphQL */ `
  subscription OnCreateItemCategoryNotify($channel: String!) {
    onCreateItemCategoryNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onUpdateItemCategoryNotify = /* GraphQL */ `
  subscription OnUpdateItemCategoryNotify($channel: String!) {
    onUpdateItemCategoryNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onDeleteItemCategoryNotify = /* GraphQL */ `
  subscription OnDeleteItemCategoryNotify($channel: String!) {
    onDeleteItemCategoryNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onCreateSellerNotify = /* GraphQL */ `
  subscription OnCreateSellerNotify($channel: String!) {
    onCreateSellerNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onUpdateSellerNotify = /* GraphQL */ `
  subscription OnUpdateSellerNotify($channel: String!) {
    onUpdateSellerNotify(channel: $channel) {
      channel
      id
    }
  }
`;

export const onDeleteSellerNotify = /* GraphQL */ `
  subscription OnDeleteSellerNotify($channel: String!) {
    onDeleteItemCategoryNotify(channel: $channel) {
      channel
      id
    }
  }
`;