import React, { useCallback, useEffect, useMemo } from 'react'
import { I18n } from 'aws-amplify';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { renderField, renderSelect } from '../../forms/fields/V0/Fields';
import Label from '../../common/Label';
import spainProvinces from '../../countriesData/spain/provinces.json';
import spainCities from '../../countriesData/spain/municipalityProvince.json';
import { Button, ChipStatus, Icon, Tooltip, Typography } from '@alegradev/smile-ui-react';
import { useTicketBaiInformation } from '../../../hooks/useTicketBaiElegible';

const ACTIVATED_KEY = 'activated';
const DEACTIVATED_KEY = 'deactivated';

const filterCitiesByProvince = (province) => {
  if (!province) return [];
  return spainCities.filter(({ key }) => {
    const [, secondParam] = key.split(', ');
    return secondParam === province;
  });
};

const TicketBaiStatusCard = ({ companyIsElectronic, isTicketBaiEligible, isAllRequiredDataFilled }) => {

  const isTicketBaiEnabled = companyIsElectronic && isTicketBaiEligible;

  const tagText = capitalize(I18n.get(
    isTicketBaiEnabled ? ACTIVATED_KEY : DEACTIVATED_KEY,
    isTicketBaiEnabled ? 'activada' : 'deshabilitado'
  ));

  const getRedirectUrl = () => {
    if (isTicketBaiEnabled) {
      return 'https://mi.alegra.com/fe-information';
    }
    return 'https://mi.alegra.com/fe-information';
  }

  const handleRedirect = (e) => {
    e.preventDefault();
    const url = getRedirectUrl();
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  return (
    <div className='border d-flex align-items-center justify-content-between p-4' style={{ borderRadius: "1rem" }}>
      <div className='d-flex'>
        <Icon icon='receipt-2'
          type="primary"
        />
        <div className='ml-3'>
          <div className='d-flex'>
            <Typography
              text="TicketBAI"
              type="body-1-bold"
              variant="primary"
              extraClassName='mr-3'
            />
            <ChipStatus
              disabled={!isTicketBaiEnabled}
              icon={isTicketBaiEnabled ? 'circle-check' : 'circle-minus'}
              iconVariant="reversed"
              label={tagText}
              variant="success"
            />
          </div>
          <Typography
            text={isTicketBaiEnabled
              ? I18n.get("ticketBaiEnabledMessage", "Tu negocio tiene habilitado el sistema BATUZ para emitir documentos electrónicos.")
              : I18n.get("ticketBaiDisabledMessage", "Habilita el sistema BATUZ para emitir documentos electrónicos en tu negocio.")}
            type="body-3-regular"
            variant="tertiary"

          />
        </div>
      </div>
      <div>
        <Tooltip
          overlay={I18n.get('tooltipCompleteData', 'Completa los datos marcados con * para iniciar la habilitación.')}
          placement="left"
          visible={!isAllRequiredDataFilled}
        >
          <Button
            onClick={handleRedirect}
            aria-label={isTicketBaiEnabled ? I18n.get('checkDetails', 'Revisar detalles') : I18n.get('enable', 'Habilitar')}
            emphasis={isTicketBaiEnabled ? 'outline' : 'filled'}
            text={isTicketBaiEnabled ? I18n.get('checkDetails', 'Revisar detalles') : capitalize(I18n.get('enable', 'habilitar'))}
            rightIcon='arrow-up-right'
            size="large"
            type='button'
            disabled={!isAllRequiredDataFilled || !getRedirectUrl()}
          />
        </Tooltip>

      </div>
    </div>
  )
};

const CompanySettings = ({ values, form }) => {
  const companyIsElectronic = values?.settings?.electronicInvoicing ?? false;
  const province = values?.address?.province?.key ?? '';

  const { isTicketBaiEligible, isAllRequiredDataFilled } = useTicketBaiInformation();

  const spainCitiesOptions = useMemo(() => filterCitiesByProvince(province), [province]);

  useEffect(() => {
    if (!province) {
      form.change('address.city', null);
    }
  }, [province]);

  const formFields = useMemo(() => [
    {
      name: 'identification',
      label: I18n.get('identification', 'identificación'),
      required: companyIsElectronic || isTicketBaiEligible,
      component: renderField,
      type: 'text',
      testId: 'client-id-input',
    },
    {
      name: 'firstName',
      label: I18n.get('taxName', 'Nombre'),
      required: true,
      component: renderField,
      type: 'text',
      testId: 'client-name-input',
    },
    {
      name: 'tradeName',
      label: I18n.get('tradeName', 'Nombre'),
      required: false,
      component: renderField,
      type: 'text',
      testId: 'client-name-input',
    },
    {
      name: 'address.address',
      label: I18n.get('address', 'Dirección'),
      required: companyIsElectronic || isTicketBaiEligible,
      component: renderField,
      type: 'text',
      props: { required: companyIsElectronic },
    },
    {
      name: 'address.province',
      label: capitalize(I18n.get('province', 'provincia')),
      required: companyIsElectronic || isTicketBaiEligible,
      component: renderSelect,
      options: spainProvinces,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      isSearchable: true,
      showClearIndicator: true,
    },
    {
      name: 'address.city',
      label: capitalize(I18n.get('city', 'ciudad')),
      required: companyIsElectronic,
      component: renderSelect,
      options: spainCitiesOptions,
      getOptionLabel: (option) => option.municipality,
      getOptionValue: (option) => option.municipality,
      isSearchable: true,
      disabled: !province,
    },
    {
      name: 'email',
      label: I18n.get('electronicMail', 'Correo electrónico'),
      required: false,
      component: renderField,
      type: 'text',
    },
    {
      name: 'phone',
      label: I18n.get('phone', 'Teléfono'),
      required: false,
      component: renderField,
      type: 'text',
    },
    {
      name: 'address.zipCode',
      label: I18n.get('zipCode', 'Código postal'),
      required: true,
      component: renderField,
      type: 'text',
    },
    {
      name: 'website',
      label: I18n.get('website', 'Sitio web'),
      required: false,
      component: renderField,
      type: 'text',
    },
  ], [companyIsElectronic, isTicketBaiEligible, spainCitiesOptions, province]);

  const renderFormField = useCallback((field) => (
    <div className="col-md-6 px-4 pb-4" key={field.name}>
      <Label title={field.label} required={field.required} />
      <Field
        name={field.name}
        component={field.component}
        type={field.type}
        data-testid={field.testId}
        {...field.props}
        options={field.options}
        getOptionLabel={field.getOptionLabel}
        getOptionValue={field.getOptionValue}
        isSearchable={field.isSearchable}
        disabled={field.disabled}
        showClearIndicator={field.showClearIndicator}
      />
    </div>
  ), []);

  return (
    <div className="w-100">
      <div className="form-row" style={{ margin: "0 -1.5rem" }}>
        {formFields.map(renderFormField)}
        {isTicketBaiEligible && (
          <div className="col-12">
            <TicketBaiStatusCard
              isTicketBaiEligible={isTicketBaiEligible}
              companyIsElectronic={companyIsElectronic}
              isAllRequiredDataFilled={isAllRequiredDataFilled}
            />
          </div>
        )}
      </div>
    </div>
  );
}


export default CompanySettings