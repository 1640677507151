import { get } from 'lodash';
import { COUNTRIES } from '../../../utils/enums/countries';

export const getAvailableQuantity = (item, idWarehouse) => {
  let availableQuantity = -1;
  const warehouses = get(item, 'inventory.warehouses');
  if (!!warehouses) {
    const stationWarehouse = warehouses.find(
      (warehouse) => +get(warehouse, 'id') === +idWarehouse
    );

    if (!!stationWarehouse) {
      availableQuantity = +get(stationWarehouse, 'availableQuantity', 0);
    }
  }

  return availableQuantity;
};

const COUNTRIES_AVAILABLE = [
  COUNTRIES.USA,
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.PANAMA,
  COUNTRIES.PERU,
  COUNTRIES.REPUBLICA_DOMINICANA,
  COUNTRIES.SPAIN,
  COUNTRIES.MEXICO
]
export const showNewStockFeature = ({country}) => {
  return COUNTRIES_AVAILABLE.includes(country)
}