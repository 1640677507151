import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';

export const OFFLINE_ACTIVE_COUNTRIES = [
  COUNTRIES.COLOMBIA,
];

export const OFFLINE_ACTIVE_IDS = [];

const useElectronicInvoiceOfflineGroup = () => {
  const [isElectronicOffline, setIsElectronicOffline] = useState(false);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);

  useEffect(() => {
    if (
      OFFLINE_ACTIVE_COUNTRIES.includes(country) ||
      OFFLINE_ACTIVE_IDS.includes(company?.id)
    ) {
      setIsElectronicOffline(true);
    }
  }, [company, country]);

  return {
    isElectronicOffline,
  };
};

export default useElectronicInvoiceOfflineGroup;
