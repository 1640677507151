import React from 'react'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js'

import { decimalPrecision, country as countrySelector, isTaxesResponsable as isTaxesResponsableSelector, companySelector, tipsSelector, idCompanySelector, membershipSelector, decimalSeparator as decimalSeparatorSelector } from '../../../selectors/company'
import { activeNumerations } from '../../../selectors/numerations'
import { getMainCurrency } from '../../../selectors/currencies';
import { calculateItemsValues } from '../../../utils';
import { assingITBISTaxesToLetter, getFormatNumberToWords, getSpainTaxCode, getSubDocumentType, isAfterDateToProduction } from './utilities'
import { useCheckTaxesSummaryAvailability } from '../../../hooks/featureAvailability/useCheckTaxesSummaryAvailability';

import taxTypeByPercentage from '../../countriesData/republicaDominicana/taxes'
import TaxDetail from './TaxDetail';
import { COUNTRIES } from '../../../utils/enums/countries';

const shouldShowDiscount = (country, invoice, numerations) => {
  switch (country) {
    case 'argentina':
      const subDocumentType = getSubDocumentType(invoice, numerations)
      if (subDocumentType === 'INVOICE_B' || subDocumentType === 'INVOICE_C')
        return false
      return true

    default:
      return true
  }
}

const shouldShowTaxes = (country, invoice, numerations) => {
  switch (country) {
    case 'argentina':
      const subDocumentType = getSubDocumentType(invoice, numerations)
      if (subDocumentType === 'INVOICE_B' || subDocumentType === 'INVOICE_C')
        return false
      return true
    case 'republicaDominicana':
      return false
    default:
      return true
  }
}

const Payments = ({ invoice, setting: { template, totalLines } }) => {
  let { items, currency, totalReceived, cashReturned } = invoice
  const mainCurrency = useSelector(getMainCurrency);
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const idCompany = useSelector(idCompanySelector);
  const decimalSeparator = useSelector(decimalSeparatorSelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector)
  const numerations = useSelector(activeNumerations);
  const isTaxesResponsable = useSelector(isTaxesResponsableSelector);
  const tipsSettings = useSelector(tipsSelector);
  let tip = new BigNumber(0);
  const preInvoiceTip = get(invoice, 'tip', false);
  const isPreInvoice = !!preInvoiceTip;
  const chargeId = tipsSettings.additionalChargeId
  const customTaxTypeByPercentage = country === 'republicaDominicana' ? assingITBISTaxesToLetter(items) : {};

  const isTaxesSummaryAvailable = useCheckTaxesSummaryAvailability();

  if (!items) return null;

  const prefix = !!get(currency, 'symbol')
    ? get(currency, 'symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  } : {
    prefix: !!get(mainCurrency, 'symbol')? get(mainCurrency, 'symbol') : '',
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  };

  const calculatePayments = (payments) => {
    let total = new BigNumber(0);

    if (payments) {
      payments.forEach((payment) => {
        total = total.plus(payment.amount);
      });
    }

    return total;
  };

  const totalPayments = calculatePayments(get(invoice, 'payments', []))

  const { subtotal, total, taxes, discount, discSubtotal, subTotalByTax, taxesByType, totalWhitoutTips } = calculateItemsValues({items, decimal, tips: preInvoiceTip, country, company, ignoreDisableTaxes: true, membership});
  const showDiscount = discount.gt(0) && shouldShowDiscount(country, invoice, numerations)
  const showTaxes = shouldShowTaxes(country, invoice, numerations)
  const showITBISTaxes = country === 'republicaDominicana';

  if (isPreInvoice) {
    if (get(preInvoiceTip, 'include', false)) {
      tip = get(preInvoiceTip, "type") === 'PERCENTAGE'
        ? subtotal.times(get(preInvoiceTip, "percentage")).div(100)
        : new BigNumber(get(preInvoiceTip, "value"));
    }
  } else {
    const additionalCharges = !!get(invoice, 'additionalCharges', []) ? get(invoice, 'additionalCharges', []) : [];
    tip = new BigNumber(additionalCharges.find(charge => charge.idCharge === chargeId)?.amount || 0);
  }

  const isAfterDate = isAfterDateToProduction(invoice, "2023-04-25");
  const isElectronic = get(invoice, "stamp", null);

  const taxedSubtotal = items.reduce((acc, item) => {
    const itemTotal = new BigNumber(get(item, 'price')).times(get(item, 'quantity')).minus(get(item, 'discount', 0));
    const hasValidTax = item.tax.some(tax => new BigNumber(tax.percentage).isGreaterThan(0) && tax.type !== 'PROPINA');;
    return hasValidTax ? acc.plus(itemTotal) : acc;
  }, new BigNumber(0));

  const exemptSubtotal = subtotal.minus(taxedSubtotal);
  const totalITBIS = taxes.filter(tax => get(tax, 'type') === 'ITBIS').reduce((acc, tax) => acc.plus(get(tax, 'value')), new BigNumber(0));

  return (
    <div className={`w-100 py-2 d-flex flex-column ${template !== 'simple' ? 'border-top border-bottom' : ''}`}>

      <div className={`${(country === "panama" && isAfterDate && isElectronic) ? "" : "text-right"} pb-2`}>
        {(!!showDiscount || !!showTaxes) && (
          <h4 className={`h4 font-weight-bold container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
            <p>{(country === "panama" && isAfterDate && isElectronic) ? I18n.get('subtotalWithoutTax', 'Subtotal sin impuestos') : country === 'spain' ? I18n.get('taxableIncome', 'Base imponible') : I18n.get('subtotal', 'subtotal')}:</p>
            <p className='pl-2'>
              {subtotal.toFormat(decimal, fmt)}
            </p>
          </h4>
        )}

        {
          !!showDiscount &&
          (
            <>
              <h4 className={`h4 container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
                <p className={country === 'republicaDominicana' ? 'font-weight-bold' : "strong"}>{I18n.get('discount', 'descuento')}</p>
                <p className='pl-2'>
                  {`-${discount.toFormat(decimal, fmt)}`}
                </p>
              </h4>
              <h4 className={`h4 container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
                <p className={country === 'republicaDominicana' ? 'font-weight-bold' : "strong"}>{country === 'spain' ? I18n.get('discountedBasis', 'Base con descuento') : I18n.get('subtotal', 'subtotal')}</p>
                <p className='pl-2'>
                  {discSubtotal.toFormat(decimal, fmt)}
                </p>
              </h4>
            </>
          )}

        {!!showTaxes
          ? (country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable)
            ? taxes.map((tax, index) => (
              <h4 key={index} className={"h4  container-text-flex"}>
                <div className='text-capitalize-first desc'>
                  {`${I18n.get('total', 'Total')} ${tax.type === "OTRO" ? tax.name?.substring(0, 3).replace(/\s*\(\d+(\.\d+)?%\)/, "") : tax.name}`}
                  {country === COUNTRIES.COLOMBIA &&
                    (get(tax, 'rate') === 'Exento' ||
                      get(tax, 'rate') === 'Excluido') &&
                    tax.rate}:
                </div>
                <div className="text-capitalize-first text-muted number pl-2">
                  {tax.value.toFormat(decimal, fmt)}
                </div>
              </h4>
            ))
            : taxes.map((tax, index) => (
              country === 'spain' ? (
                <p key={index} className='h4 container-text-flex'>
                  <p className='font-weight-bold pr-2'>
                    {getSpainTaxCode(tax).itemTaxCode}
                  </p>
                  <p className='font-weight-bold'>
                    {(country === "panama" && isAfterDate && isElectronic) ? I18n.get('taxes', 'Impuestos') :
                      tax.name}:
                  </p>
                  <p className='pl-2'>
                    {tax.value.toFormat(decimal, fmt)}
                  </p>
                </p>
              ) : (
                <h4 key={index} className={`h4 container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
                  <p className='font-weight-bold'>
                    {(country === "panama" && isAfterDate && isElectronic) ? I18n.get('taxes', 'Impuestos') :
                      tax.name}:
                  </p>
                  <p className='pl-2'>
                    {tax.value.toFormat(decimal, fmt)}
                  </p>
                </h4>
              )
            ))
          : null
        }

        {!!showITBISTaxes &&
          <>
            {!(!!showDiscount && country === 'republicaDominicana') && (
              <p>
                <strong>{I18n.get('subtotal', 'subtotal')}</strong>
                {subtotal.toFormat(decimal, fmt)}
              </p>
            )}
            {exemptSubtotal.isGreaterThan(0) && (
              <p>
                <strong>{I18n.get('exemptSubtotal', 'Subtotal Exento')}</strong>
                {exemptSubtotal.toFormat(decimal, fmt)}
              </p>
            )}
            <p>
              <strong>{I18n.get('taxedSubtotal', 'Subtotal Gravado')}</strong>
              {taxedSubtotal.toFormat(decimal, fmt)}
            </p>
            {taxes.filter(t => get(t, 'type') === "ITBIS").map((tax, index) => {
              const taxType = get(tax, 'percentage') === 0
                ? "E"
                : ![18, 16, 0].includes(Number(get(tax, 'percentage', 1)))
                  ? get(customTaxTypeByPercentage, Number(tax.percentage))
                  : get(taxTypeByPercentage, Number(tax.percentage));

              return (
                <p key={index}>
                  <span>{!!taxType ? `(${taxType}) ` : ''}{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {totalITBIS.isGreaterThan(0) && (
              <p>
                <strong>Total ITBIS</strong>
                {totalITBIS.toFormat(decimal, fmt)}
              </p>
            )}

            {taxes.filter(t => get(t, 'type') === "ISC").map((tax, index) => {
              return (
                <p key={index}>
                  <span>{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {taxes.filter(t => get(t, 'type') === "ISC").length > 1 && (
              <p>
                <strong>Total ISC</strong>
                {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'ISC' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}

            {taxes.filter(t => get(t, 'type') === "PROPINA").map((tax, index) => {
              return (
                <p key={index}>
                  <span>{`${tax.name}`}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {taxes.filter(t => get(t, 'type') === "PROPINA").length > 1 && (
              <p>
                <strong>Total Propina</strong>
                {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'PROPINA' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}

            {taxes.filter(t => get(t, 'type') === "OTRO").map((tax, index) => {
              return (
                <p key={index}>
                  <span>{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {taxes.filter(t => get(t, 'type') === "OTRO").length > 1 && (
              <p>
                <strong>Total Otros</strong>
                {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'OTRO' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}
          </>
        }

        {!!tip.isGreaterThan(0) && (
          <h3 className={`h3 font-weight-bold container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
            <p>{I18n.get('totalSale', 'Total venta')}:</p>
            <p className='pl-2'>
              {totalWhitoutTips.toFormat(decimal, fmt)}
            </p>
          </h3>
        )
        }

        {
          (!!tip.isGreaterThan(0)) &&
          <h3 className={`h3 container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
            <p>{I18n.get('tip', 'Propina')}</p>
            <p className='pl-2'>
              {tip.toFormat(decimal, fmt)}
            </p>
          </h3>
        }

        {total.minus(totalPayments).isGreaterThan(0) && (
          <>
            <h3 className={`h3 container-text-flex`}>
              <p>{I18n.get('credit', 'Crédito')}:</p>
              <p className='pl-2'>
                {total.minus(totalPayments).toFormat(decimal, fmt)}
              </p>
            </h3>
            <h3 className={`h3 container-text-flex`}>
              <p>{I18n.get('paymentCredit', 'Abono')}:</p>
              <p className='pl-2'>{totalPayments.toFormat(decimal, fmt)}</p>
            </h3>
          </>
        )}

        <h3 className={`h3 font-weight-bold container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
          <p>{!!tip.isGreaterThan(0) ? I18n.get('totalToPay', 'Total a pagar') : I18n.get('total', 'total')}:</p>
          <p className='pl-2'>
            {
              isPreInvoice
                ? total.toFormat(decimal, fmt)
                : total.plus(tip).toFormat(decimal, fmt)
            }
          </p>
        </h3>
      </div>

      {(country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable) && (
        <TaxDetail invoice={invoice} taxes={taxes} subTotalByTax={subTotalByTax} subtotal={discSubtotal} total={total} />
      )}
      {
        country === 'peru' && (
          <div className='text-left mb-3'>{capitalize(getFormatNumberToWords(total)) + ' soles'}</div>
        )
      }
      {country !== 'argentina' && (
        <div className="text-left">
          {!!totalReceived && !total.minus(totalPayments).isGreaterThan(0) &&(
            <p>
              <strong>{I18n.get('totalReceived', 'total recibido')}</strong>
              {new BigNumber(totalReceived).toFormat(decimal, fmt)}
            </p>
          )}
          {!!cashReturned && (
            <p>
              <strong>{I18n.get('cashReturned', 'cambio')}</strong>
              {new BigNumber(cashReturned).toFormat(decimal, fmt)}
            </p>
          )}
          {
            totalLines && (
              <>
                <p>
                  <strong>{I18n.get('totalRows', 'total de líneas')}</strong>
                  {items.length}
                </p>
                <p>
                  <strong>{I18n.get('totalItems', 'total de productos')}</strong>
                  {items.map(item => +get(item, 'quantity', 0)).reduce((prev, curr) => prev + curr, 0)}
                </p>
              </>
            )
          }
        </div>
      )}

    </div>
  )
};

export default Payments;