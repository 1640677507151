import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { formError, handleError } from '../../../utils/errors';
import { toast } from '../../../utils';
import { checkStationValues } from '../../../reducers/app';
import { closeModal, openModal } from '../../../reducers/modals';
import { getShiftPayments, getShiftStatus } from '../../../reducers/shifts';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../selectors/app';
import { decimalPrecision } from '../../../selectors/company';
import {
  payments as paymentsSelector,
  baseAmount,
} from '../../../selectors/shifts';
import Body from '../../forms/closeShift/NewCloseShift';
import { validate, transform } from '../../forms/closeShift/utils';

import Modal from '../../common/Modal';
import Details from './NewDetails';
import CloseShiftBottom from './CloseShiftBottom';

import { Icon } from '@alegradev/smile-ui-react';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

const NewCloseShift = () => {
  const [onSubmitEvent, setOnSubmitEvent] = useState(false);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.newCloseShift.isOpen', false)
  );
  const attemptOrigin = useSelector((state) =>
    get(state, 'modals.newCloseShift.params.attemptOrigin', false)
  );
  const decimal = useSelector(decimalPrecision);
  const payments = useSelector(paymentsSelector);
  const startingAmount = useSelector(baseAmount);
  const station = useSelector(stationSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  useEffect(() => {
    if (!!isOpen) dispatch(getShiftPayments());
  }, [isOpen, dispatch]);

  const closeModalAction = () =>
    dispatch(closeModal({ modal: 'newCloseShift' }));

  const submit = async (values) => {
    try {
      dispatch(startAction({action: 'closeShift'}));
      const canContinue = await dispatch(
        checkStationValues({ type: 'newCloseShift' })
      );
      if (!canContinue) return;
      const transformedValues = transform({
        ...payments,
        idStation: station.id,
        costCenter: station.idCostCenter,
        observations: values.observations,
      });

      await APIGraphql(
        graphqlOperation(mutations.closeShift, {
          shift: transformedValues,
        })
      );

      dispatch(getShiftStatus());

      closeModalAction();

      dispatch(endAction({action: 'closeShift'}));
      dispatch(
        sendGTMEvent('shift-closed', {
          attemptOrigin,
        })
      );

      dispatch(sendNewGTMEvent('pos-shift-closed', {
        responseTime: calculateActionTime("closeShift")
      }));

      toast.success({
        title: `${I18n.get(
          'shiftClosedSuccessfully',
          'el turno fue cerrado con éxito'
        )} 🔥`,
        subtitle: I18n.get(
          'shiftClosedSuccessfullyMessage',
          'Tu turno se encuentra cerrado y ya estás teniendo el control del efectivo en tu punto de venta.'
        ),
      });
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-shift-closed', {
          error: parseError
        })
      );
      return formError(
        error,
        I18n.get(
          'closeShiftError',
          'hubo un error a la hora de cerrar el turno'
        )
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalAction}
      customHeader={
        <div className='modal__close-shift-header d-flex justify-content-between align-items-center w-100'>
          <div className='text-capitalize-first'>
            {I18n.get('closeShift', 'cerrar turno')}
          </div>
          <div onClick={closeModalAction}>
            <Icon icon='x' extraClass='icon-gray pointer' />
          </div>
        </div>
      }
      className='modal__new-close-shift'
    >
      <Form
        onSubmit={submit}
        validate={(values) =>
          validate(values, {
            shift: { ...payments, startingAmount },
            decimal,
          })
        }
      >
        {({ handleSubmit, values, form, submitting, submitError }) => (
          <>
            <form
              noValidate
              onSubmit={handleSubmit}
              className='modal__close-shift-content'
            >
              <Details />

              <div className='w-100 text-center cash-movement-btn'>
                <button
                  type='button'
                  className='btn btn-submit'
                  onClick={() => {
                    dispatch(
                      openModal({
                        modal: 'payments',
                        params: { creationOrigin: 'from shift closing' },
                      })
                    );
                    dispatch(
                      sendGTMEvent('new-cash-movement-attempted', {
                        attemptOrigin: 'from shift closing',
                        creationStatus: 'success',
                      })
                    );
                  }}
                >
                  {I18n.get('inOutCash', 'ingresar / retirar efectivo')}
                </button>
              </div>

              <div className='close-shift-body d-flex flex-column'>
                <Body values={values} onSubmitEvent={onSubmitEvent} setOnSubmitEvent={setOnSubmitEvent} />
              </div>
            </form>
            <CloseShiftBottom
              closeModalAction={closeModalAction}
              form={form}
              submitting={submitting}
              submitError={submitError}
              setOnSubmitEvent={setOnSubmitEvent}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default NewCloseShift;
