import { get } from "lodash";
import { COUNTRIES } from "../../utils/enums/countries";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const idsCompaniesProduction = [
  '1455936',
  '1456627'
];
const enviroment = process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder'
  ? 'testing'
  : 'production'

const deployGroupDate = dayjs('2023-06-30')

export const userIsElegibleForTip = ({ country, registryDate, idCompany }) => {
  return country === COUNTRIES.COLOMBIA || country === COUNTRIES.COSTA_RICA
}

const FIRST_USER_ROLES_ELIGIBLE_COUNTRIES = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.SPAIN,
];
const SECOND_USER_ROLES_ELIGIBLE_COUNTRIES = [
  COUNTRIES.PERU,
  COUNTRIES.PANAMA,
  COUNTRIES.COSTA_RICA,
];
const firstUsersAndRolesDate = '2024-09-30';
const secondUsersAndRolesDate = '2024-10-09';

const EUROPE_TIMEZONE = 'Europe/Berlin';
const DEFAULT_TIMEZONE = dayjs.tz.guess();
export const showManageYourAccountSection = ({ country, company }) => {
  const registryDate = get(company, 'registryDate', '');

  if (FIRST_USER_ROLES_ELIGIBLE_COUNTRIES.includes(country)) {
    const timezoneAdjustedDate = dayjs(registryDate).tz(EUROPE_TIMEZONE);
    return !timezoneAdjustedDate.isBefore(
      dayjs.tz(firstUsersAndRolesDate, EUROPE_TIMEZONE)
    );
  }

  if (SECOND_USER_ROLES_ELIGIBLE_COUNTRIES.includes(country)) {
    const timezoneAdjustedDate = dayjs(registryDate).tz(DEFAULT_TIMEZONE);
    return !timezoneAdjustedDate.isBefore(
      dayjs.tz(secondUsersAndRolesDate, DEFAULT_TIMEZONE)
    );
  }

  return false;
};