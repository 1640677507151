import { get, isEmpty, isString } from 'lodash'
import { I18n } from 'aws-amplify';
import { isUserElegibleForNewRefund } from '../refunds/utils';
import dayjs from 'dayjs';
import { DOCUMENT_TYPES } from '../../utils/enums/documentTypes';
import { BADGE_STATUSES, LEGAL_STATUSES, LEGAL_STATUS_KEYS } from '../../utils/enums/legalStatus';
import { COUNTRIES } from '../../utils/enums/countries';

/**
 * Generates formatting options based on the invoice and main currency.
 *
 * @param {Object} invoice - The invoice object containing currency information.
 * @param {Object} mainCurrency - The main currency object containing symbol information.
 * @param {string} [decimalSeparator='.'] - The decimal separator to be used in formatting.
 * @returns {Object} - An object containing formatting options.
 */
export const getFmt = (invoice, mainCurrency, decimalSeparator = '.') => {
  let prefix = '';

  if (invoice?.currency?.symbol) {
    prefix = invoice.currency.symbol;
  } else if (mainCurrency?.symbol) {
    prefix = mainCurrency.symbol;
  }

  const groupSeparator = decimalSeparator === ',' ? '.' : ',';
  const groupSize = 3;

  return {
    prefix,
    decimalSeparator,
    groupSeparator,
    groupSize,
  };
};

/**
 * Retrieves the reference from an item, handling offline scenarios and parsing JSON when necessary.
 *
 * @param {Object} item - The item object containing the reference data.
 * @param {boolean} offline - Flag indicating whether the operation is offline.
 * @returns {string|Object} - The reference string or parsed object, or an empty string if not available.
 */
export const getItemReference = (item, offline) => {
  try {
    if (offline) {
      if (item?.reference?.reference) {
        return item.reference.reference;
      }

      if (item?.reference) {
        return item.reference;
      }

      return '';
    } else {
      if (item?.reference?.reference) {
        return JSON.parse(item.reference.reference);
      }

      if (item?.reference) {
        return JSON.parse(item.reference);
      }

      return '';
    }
  } catch (error) {
    return '';
  }
};

/**
 * Determines the legal status key for a given invoice based on its properties, numerations, and country.
 *
 * @param {Object} invoice - The invoice object containing relevant details.
 * @param {Array} numerations - A list of numeration objects used for lookup.
 * @param {string} country - The country code associated with the invoice.
 * @returns {string|null} - The corresponding legal status key or null if input is invalid.
 */
export const getLegalStatusKey = (invoice, numerations = [], country) => {
  if (!invoice || !invoice.numberTemplate) {
    console.warn('Invalid invoice object provided.');
    return LEGAL_STATUS_KEYS.UNKNOWN;
  }

  const isDocEq = invoice.numberTemplate.documentType === DOCUMENT_TYPES.SALE_TICKET;
  const hasItemsWithNoIvaDays = invoice.saleTicketHasItemsWithNoIvaDays === 'yes';
  const legalStatus = invoice.stamp?.legalStatus ?? null;
  const invoiceNumeration = numerations.find(n => n.id === invoice.numberTemplate.id);
  const isPOSElectronicDocument = invoiceNumeration?.documentType === DOCUMENT_TYPES.SALE_TICKET && invoiceNumeration?.isElectronic;

  // Handle specific POS electronic document cases
  if (
    isPOSElectronicDocument &&
    (legalStatus === LEGAL_STATUSES.STAMPED_AND_ACCEPTED || legalStatus === LEGAL_STATUSES.STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS)
  ) {
    return LEGAL_STATUS_KEYS.ISSUED_ALTER;
  }

  // Handle sale ticket with or without IVA days
  if (isDocEq && hasItemsWithNoIvaDays) {
    return legalStatus ? LEGAL_STATUS_KEYS.ISSUED : LEGAL_STATUS_KEYS.CONTINGENCY;
  }

  // Handle electronic numeration
  if (invoiceNumeration?.isElectronic) {
    if (!legalStatus) {
      return country === COUNTRIES.PANAMA ? LEGAL_STATUS_KEYS.IN_PROCESS : LEGAL_STATUS_KEYS.TO_BE_ISSUED;
    }
  }

  // Default switch for legalStatus
  switch (legalStatus) {
    case LEGAL_STATUSES.ACCEPTED:
    case LEGAL_STATUSES.ACCEPTED_WITH_OBSERVATIONS:
    case LEGAL_STATUSES.PENDING:
      return LEGAL_STATUS_KEYS.TO_BE_ISSUED;
    case LEGAL_STATUSES.STAMPED_AND_ACCEPTED:
    case LEGAL_STATUSES.STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS:
      return LEGAL_STATUS_KEYS.ISSUED;
    case LEGAL_STATUSES.REJECTED:
    case LEGAL_STATUSES.STAMPED_AND_REJECTED:
      return LEGAL_STATUS_KEYS.REJECTED;
    case LEGAL_STATUSES.STAMPED_AND_WAITING_RESPONSE:
      return LEGAL_STATUS_KEYS.IN_PROCESS;
    default:
      return legalStatus;
  }
}

/**
 * Maps legal status keys to badge statuses.
 *
 * @param {string} status - The legal status key.
 * @param {string} country - The country code.
 * @returns {string} - The corresponding badge status.
 */
export const legalStatusToBadgeStatus = (status, country) => {
  const statusMap = {
    [LEGAL_STATUS_KEYS.ISSUED]: BADGE_STATUSES.CLOSED,
    [LEGAL_STATUS_KEYS.ISSUED_ALTER]: BADGE_STATUSES.CLOSED,
    [LEGAL_STATUS_KEYS.REJECTED]: BADGE_STATUSES.OPEN,
    [LEGAL_STATUS_KEYS.TO_BE_ISSUED]: BADGE_STATUSES.VOID,
    [LEGAL_STATUS_KEYS.IN_PROCESS]: country === COUNTRIES.PANAMA ? BADGE_STATUSES.PROCESS : BADGE_STATUSES.VOID,
  };

  return statusMap[status] || BADGE_STATUSES.DEFAULT;
};

/**
 * Renders the client's identification in a formatted string.
 *
 * @param {Object} client - The client object containing identification data.
 * @returns {string} - The formatted identification string or an empty string if not available.
 */
export const renderClientIdentification = (client) => {
  if (!client) return '';

  const identification = client?.identification;
  if (!identification) return '';

  if (typeof identification === 'string') {
    return ` (${identification})`;
  }

  const referenceNumber = identification?.number;
  return referenceNumber ? ` (${referenceNumber})` : '';
};

/**
 * Renders the client's full name in a formatted string.
 *
 * @param {Object} client - The client object containing name data.
 * @returns {string} - The formatted full name or an empty string if not available.
 */
export const renderClientName = (client) => {
  if (!client) return '';

  const name = client?.name;
  if (!name) return '';

  if (typeof name === 'string') {
    return name;
  }

  const firstName = name.firstName || '';
  const secondName = name.secondName ? ` ${name.secondName}` : '';
  const lastName = name.lastName ? ` ${name.lastName}` : '';

  return `${firstName}${secondName}${lastName}`.trim();
};

/**
 * Strategy functions for determining void titles based on country.
 */
const voidTitleStrategies = {
  [COUNTRIES.COLOMBIA]: (invoice) => {
    const numerationIsElectronic = invoice?.numberTemplate?.isElectronic;
    const documentType = invoice?.numberTemplate?.documentType;

    if (documentType === DOCUMENT_TYPES.SALE_TICKET && numerationIsElectronic) {
      return I18n.get('applyRefund.adjustmentNote', 'Aplicar nota de ajuste');
    }
    if (documentType === DOCUMENT_TYPES.INVOICE && numerationIsElectronic) {
      return I18n.get('applyRefund.creditNote', 'Aplicar nota credito');
    }
    return I18n.get('void', 'anular');
  },
  [COUNTRIES.SPAIN]: () => I18n.get('applyRefund', 'Aplicar devolución'),
};

/**
 * Retrieves the appropriate void title based on the invoice and country using Strategy Pattern.
 *
 * @param {Object} invoice - The invoice object containing number template information.
 * @param {string} country - The country code to determine the void title.
 * @returns {string} - The localized void title.
 */
export const getVoidTitle = (invoice, country) => {
  const strategy = voidTitleStrategies[country];
  if (strategy) {
    return strategy(invoice);
  }
  return I18n.get('void', 'anular');
};

/**
 * Strategy functions to determine refund eligibility based on country.
 */
const eligibilityStrategies = {
  [COUNTRIES.COLOMBIA]: (invoice, company) => {
    const documentType = invoice?.numberTemplate?.documentType;
    const numerationIsElectronic = invoice?.numberTemplate?.isElectronic;
    const isPaidCompletely = invoice?.status === 'closed';

    const userEligible = isUserElegibleForNewRefund(COUNTRIES.COLOMBIA, company);
    const isInvoiceOrSaleTicketElectronic =
      numerationIsElectronic &&
      (documentType === DOCUMENT_TYPES.INVOICE || documentType === DOCUMENT_TYPES.SALE_TICKET);

    return userEligible && !isPaidCompletely && isInvoiceOrSaleTicketElectronic;
  },
  [COUNTRIES.SPAIN]: (invoice, company) => {
    const isPaidCompletely = invoice?.status === 'closed';
    const numerationIsElectronic = invoice?.numberTemplate?.isElectronic;
    const documentType = invoice?.numberTemplate?.documentType;

    const userEligible = isUserElegibleForNewRefund(COUNTRIES.SPAIN, company);
    const isInvoiceOrSaleTicketElectronic =
      numerationIsElectronic &&
      (documentType === DOCUMENT_TYPES.INVOICE || documentType === DOCUMENT_TYPES.SALE_TICKET);

    return userEligible && !isPaidCompletely && isInvoiceOrSaleTicketElectronic;
  },
};

/**
 * Determines if an invoice is eligible for applying a refund based on country and company policies using Strategy Pattern.
 *
 * @param {Object} invoice - The invoice object containing necessary details.
 * @param {string} country - The country code to determine eligibility criteria.
 * @param {Object} company - The company object to check company-specific refund policies.
 * @returns {boolean} - Returns true if eligible for applying a refund, otherwise false.
 */
export const isElegibleForApplyRefund = (invoice, country, company) => {
  const strategy = eligibilityStrategies[country];
  if (strategy) {
    return strategy(invoice, company);
  }
  return false;
};

/**
 * Determines whether to show the void icon based on invoice eligibility and country.
 *
 * @param {Object} invoice - The invoice object containing necessary details.
 * @param {string} country - The country code to determine if the void icon should be shown.
 * @param {Object} company - The company object to check company-specific refund policies.
 * @returns {boolean} - Returns true if the void icon should be displayed, otherwise false.
 */
export const showVoidIcon = (invoice, country, company) => {
  if ([COUNTRIES.COLOMBIA, COUNTRIES.SPAIN].includes(country)) {
    return isElegibleForApplyRefund(invoice, country, company);
  }
  return false;
}

export const parseStringBarCodeContentToObject = (input) => {
  if (isEmpty(input)) return {};
  const lines = input.split('\n');
  const result = {};
  lines.forEach((line) => {
    const [key, value] = line.split(': ').map((item) => item.trim());
    if (key) {
      result[key] = value;
    }
  });
  return result;
};

export const formatStepperDate = (dateString) => {
  const date = dayjs(dateString);
  const dateFormat = 'DD/MMM';
  return date.format(dateFormat).toUpperCase();
};

export const formatStepperTime = (timeString) => {
  const timePart = timeString.split('-')[0];
  const [hours, minutes] = timePart.split(':');
  return `${hours}:${minutes}`;
};

export const formatStepperTimeFromDate = (dateString) => {
  const date = dayjs(dateString);
  const time = date.format('HH:mm');
  return time;
}
