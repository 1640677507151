import { Form } from 'react-final-form';
import {
  transform,
  validate,
  validationPreviousCreation,
} from '../../forms/invoice/utils';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currency,
  total,
  numeration as invoiceNumerationSelector,
  client as clientSelector,
  items as itemsSelector,
  paymentMethod,
  isPaymentMethodSelected,
} from '../../../selectors/activeInvoice';
import { getMainCurrency } from '../../../selectors/currencies';
import {
  isOnlyInvoicingPlan,
  country as countrySelector,
  anotation as companyAnotation,
  decimalPrecision,
} from '../../../selectors/company';
import { capitalize, get } from 'lodash';
import { economicActivitiesSelector } from '../../../selectors/auth';
import {
  station as stationSelector,
  stationInvoiceNumeration,
  electronicInvoicing,
  stationCashBank,
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  stationSeller,
} from '../../../selectors/app';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { I18n } from 'aws-amplify';
import NewTotal from './NewTotal';
import NewInvoiceMethods from './NewModalMethods';
import { useDeviceType } from '../../../hooks/useDeviceType';
import {
  clear,
  payInvoice,
  setIsPaymentMethodSelected,
  setSettings,
} from '../../../reducers/activeInvoice';
import NewBasicData from '../../forms/invoice/BasicData/NewBasicData';
import Seller from '../seller/Seller';
import { formError } from '../../../utils/errors';
import { checkStationValues } from '../../../reducers/app';
import { Tabs, useModal } from '@alegradev/smile-ui-react';
import { openModal } from '../../../reducers/modals';
import { allPaymentMethods } from '../../../selectors/paymentMethods';
import Notification from '../common/Notification';
import { replaceAndParse, toast } from '../../../utils';
import ReportsAPI from '../../../reducers/balanceByClient';
import { showCreditLimitField } from '../../forms/contact/utils';
import { addTotalToCollect } from '../../../reducers/totalToCollect';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { allTotalToCollectData } from '../../../selectors/totalToCollect';
import { calculateInvoiceReceived } from './util';
import BigNumber from 'bignumber.js';
import { useElectronicInvoiceOfflineGroup } from '../../../hooks/useElectronicInvoiceOfflineGroup';

const NewInvoice = () => {
  const { isElectronicOffline } = useElectronicInvoiceOfflineGroup();
  const balaceByClientRef = useRef(false);
  const connectionStatus = useConnectionStatus();
  const [isPaying, setIsPaying] = useState(false);
  const [isTotalToCollectLoading, setIsTotalToCollectLoading] = useState(false);
  const [totalToCollect, setTotalToCollect] = useState(0);
  const [addPayment, setAddPayment] = useState(true);
  const [canCredit, setCanCredit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeNequiPush, setActiveNequiPush] = useState(true);
  const dispatch = useDispatch();
  const station = useSelector(stationSelector);
  const totalPrice = useSelector(total);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const aditionalSettings = useSelector(
    (state) => state.aditionalSettings.settings
  );
  const economicActivities = useSelector(economicActivitiesSelector);
  const mainNumeration = useSelector(stationInvoiceNumeration);
  const invoiceNumeration = useSelector(invoiceNumerationSelector);
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration;
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const defaultAnotation = useSelector(companyAnotation);
  const client = useSelector(clientSelector);
  const items = useSelector(itemsSelector);
  const synchronizingClient = useSelector((state) =>
    get(state, 'clients.synchronizingClient', false)
  );
  const paymentMethodSelected = useSelector(paymentMethod);
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const paymentMethods = useSelector(allPaymentMethods);
  const allTotalToCollect = useSelector(allTotalToCollectData);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const { closeModal: closeNewModal, updateModal } = useModal();

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const deviceType = useDeviceType();

  const currencyCode = !!get(selectedCurrency, 'code')
    ? get(selectedCurrency, 'code')
    : !!get(mainCurrency, 'code')
      ? get(mainCurrency, 'code')
      : '';
  const prefix = !!get(selectedCurrency, 'symbol')
    ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol')
      ? get(mainCurrency, 'symbol')
      : '';

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };

  const cashBank = useSelector(stationCashBank);
  const debitBank = useSelector(stationDebitBank);
  const creditBank = useSelector(stationCreditBank);
  const transferBank = useSelector(stationTransferBank);
  const seller = useSelector(stationSeller);

  const getIdPaymentMethod = (values) => {
    const method = get(values, 'method', null);
    const paymentMethod = paymentMethods.find((pm) => pm.name === method);
    return paymentMethod ? paymentMethod.idLocal : null;
  };
  const setTotalToCollectToLocalDB = ({ received, total }) => {
    const clientId = get(client, 'id', null);
    const creditLimit = get(client, 'creditLimit', null);
    const receivedConverted = new BigNumber(received);
    if (receivedConverted.isLessThan(total) && creditLimit !== null) {
      const newTotalToCollect = total.minus(receivedConverted);
      const localBalance = getLocalBalance(clientId);
      const currentTotalToCollect = localBalance
        ? new BigNumber(getLocalBalance(clientId))
        : new BigNumber(0);
      const amount = newTotalToCollect.plus(currentTotalToCollect);
      dispatch(
        addTotalToCollect({
          id: clientId,
          totalToCollect: amount.toNumber(),
        })
      );
    }
  };
  const submit = async (values) => {
    const validation = validationPreviousCreation({
      ...values,
      country,
      client,
      numeration,
      items,
    });
    if (validation.hasError) {
      console.log('errors', validation.errors);
      validation.errors.forEach((err) => {
        toast[err.type]({ ...err });
      });
      await sleep(100);
      return;
    }

    try {
      updateModal('NewInvoice', {
        primaryAction: {
          loading: true,
        },
      });
      setIsPaying(true);
      setLoading(true);
      const canContinue = await dispatch(
        checkStationValues({ type: 'invoice', values })
      );
      if (!canContinue) return;

      const transformedValues = transform(
        values,
        country,
        getIdPaymentMethod(values)
      );
      await dispatch(payInvoice(transformedValues, isElectronicOffline));

      closeNewModal('NewInvoice');
      updateModal('NewInvoice', {
        primaryAction: {
          loading: true,
        },
      });
      if (!(!!pendingInvoicesEnabled && !onlyInvoicingPlan)) {
        dispatch(clear());
        if (
          country === COUNTRIES.COSTA_RICA &&
          !!aditionalSettings['economicActivity']
        ) {
          const economicActivity = economicActivities.find(
            (activity) => activity.main === true
          );
          dispatch(setSettings({ economicActivity }));
        }
      }
      setTotalToCollectToLocalDB({
        received: calculateInvoiceReceived(values),
        total: totalPrice,
      });
      dispatch(openModal({ modal: 'newInvoiceSaved' }));
    } catch (error) {
      console.error(error);

      if (!!get(error, 'createProvisionalTicket')) {
        setIsPaying(false);
        dispatch(clear());
        closeNewModal('NewInvoice');
        dispatch(openModal({ modal: 'provisionalTicketSaved' }))
      } else {
        setIsPaying(false);
        updateModal('NewInvoice', {
          primaryAction: {
            loading: false,
          },
        });
      }
      setLoading(false);

      return formError(
        get(error, "message"),
        I18n.get(
          'createInvoiceError',
          'hubo un error en la creación de la factura'
        ),
        country
      );
    }
    setLoading(false);
  };

  const getLocalBalance = (clientId) => {
    const foundItem = allTotalToCollect.find((item) => item.id === clientId);
    return foundItem ? foundItem.totalToCollect : null;
  };

  const getBalanceByClient = async () => {
    setIsTotalToCollectLoading(true);

    try {
      let amount = null;
      const clientId = get(client, 'id', null);

      if (connectionStatus) {
        try {
          const response = await ReportsAPI.get(
            `client_id=${clientId}&balance_type=totalToCollect`
          );
          amount = get(response, 'data.totalToCollect', null);

          dispatch(
            addTotalToCollect({
              id: clientId,
              totalToCollect: amount,
            })
          );
        } catch (apiError) {
          console.error('Error al consultar la API:', apiError);
          amount = getLocalBalance(clientId);
        }
      } else {
        amount = getLocalBalance(clientId);
      }

      setTotalToCollect(amount);
    } catch (error) {
      console.error('Error en el proceso:', error);
    } finally {
      setIsTotalToCollectLoading(false);
    }
  };

  useEffect(() => {
    if (!balaceByClientRef.current && showCreditLimitField(country)) {
      getBalanceByClient();
      balaceByClientRef.current = true;
    }

    return () => {
      dispatch(setIsPaymentMethodSelected(false));
    };
  }, []);

  return (
    <Form
      onSubmit={submit}
      validate={(values) =>
        validate(
          { ...values, country, decimal, isDecimalActive },
          {
            total: totalPrice,
            country,
          }
        )
      }
      initialValues={{
        cashBank,
        debitBank,
        creditBank,
        transferBank,
        numeration,
        seller,
        anotation: defaultAnotation,
        paymentMethod: {
          value: 'CASH',
          label: capitalize(I18n.get('paymentForm.Cash', 'Contado')),
        },
        email: get(client, 'email', null),
        phone: get(client, 'mobile', null),
        method: paymentMethodSelected,
      }}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        values,
        form,
        error,
        pristine,
        submitting,
        submitError,
        submitFailed,
      }) => {
        const anotationMaxLimit =
          country === 'republicaDominicana' &&
          isElectronic &&
          get(values, 'anotation.length', 0) > 250;

        return (
          <div className='modal__invoice'>
            <form
              noValidate
              data-testid='new-form-check-in'
              onSubmit={handleSubmit}
            >
              <div className='d-flex flex-column gap-2'>
                <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                  <div className='w-100'>
                    <NewTotal values={values} form={form} total={totalPrice} />
                  </div>
                  {values.method === 'nequiPush' &&
                    !values.transfer &&
                    isPaymentSelected && (
                      <div className='w-100'>
                        <Tabs
                          direction='column'
                          defaultTab={activeNequiPush ? 1 : 2}
                          items={[
                            {
                              id: 1,
                              title: I18n.get(
                                'sendRequestToApp',
                                'Enviar solicitud a la app'
                              ),
                            },
                            {
                              id: 2,
                              title: I18n.get(
                                'scanQRCode',
                                'Escanear código QR'
                              ),
                            },
                          ]}
                          onTabChanged={(value) => {
                            setActiveNequiPush(value === 1);
                          }}
                          simple
                        />
                      </div>
                    )}
                  <div className='d-flex flex-column align-items-center w-100'>
                    <div
                      className={`${
                        deviceType !== 'mobile'
                          ? 'payment-method-container'
                          : 'px-0'
                      } col-sm-12`}
                    >
                      {addPayment && (
                        <div
                          className={
                            !!values.method && isPaymentSelected ? 'd-none' : ''
                          }
                        >
                          <NewInvoiceMethods form={form} total={totalPrice} />
                        </div>
                      )}
                    </div>
                    <div
                      className={`flex-column flex-sm-row w-100 ${
                        !!values.method && !isPaymentSelected
                          ? 'd-none'
                          : 'd-flex'
                      }`}
                    >
                      <div className='w-100 modal__invoice-basic-data'>
                        <NewBasicData
                          anotationMaxLimit={anotationMaxLimit}
                          values={values}
                          form={form}
                          total={totalPrice}
                          fmt={fmt}
                          currencyCode={currencyCode}
                          activeNequiPush={activeNequiPush}
                          totalToCollect={totalToCollect}
                          isTotalToCollectLoading={isTotalToCollectLoading}
                          isPaying={isPaying}
                          setCanCredit={setCanCredit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!!error && !!submitFailed && (
                <Notification
                  isOpen={true}
                  text={replaceAndParse(error)}
                  type='error'
                />
              )}

              {!!submitError && !!submitFailed && (
                <Notification
                  isOpen={true}
                  text={replaceAndParse(submitError)}
                  type='error'
                />
              )}
            </form>
            <Seller onCreated={(seller) => form.change('seller', seller)} />
          </div>
        );
      }}
    </Form>
  );
};

export default NewInvoice;
