import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { BigNumber } from 'bignumber.js'

import { closeModal, openModal } from '../../../reducers/modals';
import { companySelector, country as countrySelector, decimalPrecision, decimalSeparator as decimalSeparatorSelector, localSettings as localSettingsSelector } from '../../../selectors/company'
import { getMainCurrency } from '../../../selectors/currencies'
import Modal from '../../common/Modal';
import BodyInvoiceSaved from './BodyInvoiceSaved';
import BottomInvoiceSaved from './BottomInvoiceSaved';
// import { calculateItemsValues } from '../../../utils';
// import Cookies from 'js-cookie';
// import dayjs from 'dayjs';
// import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import useOfflineStatusAvailable from '../../../hooks/useOfflineStatusAvailable/hook';

import { Icon, Typography } from '@alegradev/smile-ui-react';
import InvoiceOfflineSaved from './InvoiceOfflineSaved';
import NewBodyInvoiceSaved from './NewBodyInvoiceSaved';
import { newShareInvoiceAvailable } from '../ShareInvoice/utils';

const InvoiceSaved = () => {
  const { isVersionAvailable } = useOfflineStatusAvailable();
  const decimalSeparator = useSelector(decimalSeparatorSelector);
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.invoiceSaved.isOpen', false));
  const invoiceCreated = useSelector(state => get(state, 'print.invoice', null));
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector);
  // const localSettings = useSelector(localSettingsSelector);
  const items = get(invoiceCreated, 'items', null);
  const prefix = !!get(invoiceCreated, 'currency.symbol') ? get(invoiceCreated, 'currency.symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  }

  const getTitle = () => {
    let title = I18n.get('invoiceSaved', 'factura guardada');

    if (country === 'panama' && !!get(invoiceCreated, 'numberTemplate.isElectronic'))
      title = I18n.get('electronicInvoiceSaved', 'Factura electrónica guardada')


    if (country === 'colombia' && (get(invoiceCreated, 'numberTemplate.prefix') === 'EPOS' || !!get(invoiceCreated, 'numberTemplate.linkedDevices', null)))
      title = I18n.get('sellSaved', 'Venta guardada')

    if (country === 'colombia' && (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket') && get(invoiceCreated, 'numberTemplate.isElectronic'))
      title = I18n.get('electronicPOSDocumentSaved', 'Documento POS electrónico guardado')

    if (country === 'peru' && (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'))
      title = I18n.get('saleTicketSaved', 'Boleta guardada')

    if (country === 'costaRica' && (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket'))
      title = I18n.get('saleTicketSaved', 'Tiquete guardado')

    if (
      (country === 'spain') &&
      (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket')
    ) {
      title = I18n.get('invoiceModalTitle.saleTicketSaved', 'Ticket guardado');
    }
    return title;
  }

  const handleClose = () => {
    // const cookieName = `reminder5UVT`
    // const cookieValue = Cookies.get(cookieName)
    // const cookieDate = !!cookieValue ? cookieValue : null;

    // const showReminder5UVT = get(localSettings, 'showReminder5UVT', false) && (!cookieDate ? true : dayjs().isAfter(parseInt(cookieDate)));

    dispatch(closeModal({ modal: 'invoiceSaved' }))
    if (!items)
      return;

    // const { discSubtotal } = calculateItemsValues({items, decimal, country, company});
    // const colombiaUVT = getColombiaUVT();
    // if (country === 'colombia' && showReminder5UVT && !get(localSettings, 'automationFEEnabled', true) && get(invoiceCreated, 'numberTemplate.documentType', null) === 'saleTicket' && discSubtotal >= colombiaUVT?.UVT) {
    //   Cookies.set(cookieName, dayjs().add(1, 'day').startOf('day').valueOf());
    //   dispatch(openModal({ modal: 'reminder5UVT' }));
    // }
  }

  if (isVersionAvailable && (!isOnline || !isApiAvailable)) {
    return (
      <InvoiceOfflineSaved
        isOpen={isOpen}
        isOnline={isOnline}
        handleClose={handleClose}
        invoiceCreated={invoiceCreated}
        decimal={decimal}
        fmt={fmt}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={getTitle()}
      className="modal__invoice"
    >
      <div className="d-flex flex-column p-3 justify-content-center align-items-center">
        <div className="p-4 d-flex justify-content-center align-items-center">
          <Icon icon='circle-check' size='extraLarge' />
        </div>
        {!!invoiceCreated && (
          <div className="d-flex align-items-center">
            <p className="h3 text-muted text-uppercase mr-2">
              {I18n.get('total', 'total')}
            </p>
            <h1>
              {new BigNumber(invoiceCreated.total).toFormat(decimal, fmt)}
            </h1>
          </div>
        )}

        {!!invoiceCreated && (
          <div className="d-flex align-items-center">
            <p className="h4 text-danger text-uppercase mr-2">
              {I18n.get('change', 'Cambio')}
            </p>
            <h2>
              {new BigNumber(invoiceCreated.cashReturned).toFormat(decimal, fmt)}
            </h2>
          </div>
        )}

        {get(company, 'id', null) === '1507139' || newShareInvoiceAvailable({ country }) ? (
          <NewBodyInvoiceSaved />
        ) : (
          <BodyInvoiceSaved />
        )}

      </div>

      <BottomInvoiceSaved handleClose={handleClose} />
    </Modal>
  )
}

export default InvoiceSaved;