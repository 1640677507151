import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';

const modalInitial = {
  isOpen: false,
  params: null,
};

const initialState = {
  invoice: modalInitial,
  newInvoice: modalInitial,
  item: modalInitial,
  invoiceSaved: modalInitial,
  newInvoiceSaved: modalInitial,
  sendEmail: modalInitial,
  logoutConfirm: modalInitial,
  openShift: modalInitial,
  closeShift: modalInitial,
  newCloseShift: modalInitial,
  payments: modalInitial,
  variant: modalInitial,
  refunds: modalInitial,
  editInvoice: modalInitial,
  voidInvoice: modalInitial,
  station: modalInitial,
  welcome: modalInitial,
  tutorialWizard: modalInitial,
  renamePendingInvoice: modalInitial,
  deletePendingInvoice: modalInitial,
  stationSettings: modalInitial,
  shortcuts: modalInitial,
  voidInvoiceCause: modalInitial,
  onboardingEnd: modalInitial,
  currency: modalInitial,
  planLimitReached: modalInitial,
  companyEmail: modalInitial,
  suspendedPlan: modalInitial,
  warehouse: modalInitial,
  store: modalInitial,
  customField: modalInitial,
  itemCategory: modalInitial,
  priceList: modalInitial,
  dailyReport: modalInitial,
  cancelItemCreation: modalInitial,
  activate5UVT: modalInitial,
  stationSetting5UVT: modalInitial,
  invoice5UVT: modalInitial,
  invoice5UVTNewClient: modalInitial,
  deactivate5UVT: modalInitial,
  limitedUser5UVT: modalInitial,
  configureWarehouse: modalInitial,
  reminder5UVT: modalInitial,
  activateWompiIntegration: modalInitial,
  electronicPayrollTutorial: modalInitial,
  membershipPaymentDeclined: modalInitial,
  connectionRestored: modalInitial,
  connectionMissed: modalInitial,
  changeToOrdinaryNumeration: modalInitial,
  paymentMethod: modalInitial,
  tipsModalNotification: modalInitial,
  companyDataUpdate: modalInitial,
  payInvoice: modalInitial,
  customDataReceipt: modalInitial,
  updateSpainClient: modalInitial,
  provisionalTicketSaved: modalInitial,
};

const appSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state[action.payload.modal] = {
        isOpen: true,
        params: action.payload.params,
      };
    },
    changeModalParams: (state, action) => {
      state[action.payload.modal] = {
        ...state[action.payload.modal],
        params: merge(
          state[action.payload.modal].params,
          action.payload.params
        ),
      };
    },
    closeModal: (state, action) => {
      state[action.payload.modal] = { isOpen: false, params: null };
    },
    closeAll: (state) => {
      Object.keys(state).map((key) => {
        state[key] = modalInitial;
        return null;
      });
    },
    closeAllModals: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  openModal,
  closeModal,
  closeAll,
  closeAllModals,
  changeModalParams,
} = actions;

export default reducer;
