import React, { useEffect, useState } from 'react';
import QRTransfer from './QRTransfer';
import NewCash from './NewCash';
import NewCard from './NewCard';
import NewTransfer from './NewTransfer';
import NewCombined from './NewCombined';
import { capitalize, debounce, get, includes } from 'lodash';
import CustomPaymentMethod from './CustomPaymentMethod';
import { useDispatch, useSelector } from 'react-redux';
import { allPaymentMethods } from '../../../../selectors/paymentMethods';
import {
  client as clientSelector,
  isPaymentMethodSelected,
  total as totalSelector,
} from '../../../../selectors/activeInvoice';
import { Field } from 'react-final-form';
import { Divider, Typography, useModal } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { openModal } from '../../../../reducers/modals';
import { checkFeatureLimit } from '../../../../reducers/membership';
import { activeSellers } from '../../../../selectors/sellers';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { hasPermissionTo } from '../../../../selectors/auth';
import NewChange from '../../../modals/invoice/NewChange';
import NewBancolombiaQR from './NewBancolombiaQR';
import NewPushNotification from './NewPushNotification';
import NewNequiQR from './NewNequiQR';
import useInvoiceChange from '../../../modals/invoice/hooks/useInvoiceChange';
import BigNumber from 'bignumber.js';
import CreditLimitNotification from '../CreditLimitNotification';

const NewBasicData = ({
  anotationMaxLimit,
  values,
  form,
  total,
  fmt,
  currencyCode,
  activeNequiPush,
  setExternalPaymentSuccess,
  editInvoice = false,
  totalToCollect,
  isTotalToCollectLoading,
  isPaying = false,
  setCanCredit
}) => {
  const client = useSelector(clientSelector);
  const paymentMethods = useSelector(allPaymentMethods);
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const totalPrice = useSelector(totalSelector);
  const { updateModal } = useModal();

  const [canCreditInvoice, setCanCreditInvoice] = useState(false);
  const [creditLimitAmount, setCreditLimitAmount] = useState(new BigNumber(0));
  const [firstLoad, setFirstLoad] = useState(true);

  const dispatch = useDispatch();

  if (!values) return null;
  const defaultMethods = [
    'cash',
    'combined',
    'transfer',
    'nequi',
    'bancolombiaQR',
    'nequiPush',
    'debit',
    'credit',
  ];

  const { change, received } = useInvoiceChange(values, total);

  const isAbleToCredit = (creditLimit, change, totalToCollect) => {
    const bigTotal = new BigNumber(total);
    if (creditLimit === null || bigTotal.isLessThanOrEqualTo(received)) {
      return true;
    }

    const bigCreditLimit = new BigNumber(creditLimit);
    const bigTotalToCollect = new BigNumber(totalToCollect);

    let totalCredit = bigTotalToCollect;

    if (change.isGreaterThanOrEqualTo(0)) {
      totalCredit = bigTotalToCollect.plus(change);
    }

    return bigCreditLimit.isGreaterThanOrEqualTo(totalCredit);
  };

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      const creditLimit = get(client, 'creditLimit', null);
      const canCredit = isAbleToCredit(creditLimit, change, totalToCollect);
      if (received !== null) {
        setFirstLoad(false);
      } else {
        setFirstLoad(true);
      }

      setCanCreditInvoice(canCredit);
      setCreditLimitAmount(
        creditLimit ? new BigNumber(creditLimit) : new BigNumber(0)
      );

      if (isPaymentSelected && !editInvoice) {
        updateModal('NewInvoice', {
          primaryAction: {
            text: I18n.get('sell', 'Vender'),
            loading: isTotalToCollectLoading || isPaying,
            disabled: isTotalToCollectLoading || !canCredit,
            onClick: () => {
              form.submit();
            },
          },
        });
      }
    }, 300);

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [
    isPaymentSelected,
    editInvoice,
    isTotalToCollectLoading,
    client,
    totalToCollect,
    change,
    received,
    isPaying
  ]);

  const checkCustomPaymentMethod = () => {
    const isCustomPaymentMethod = !includes(defaultMethods, values.method);
    if (isCustomPaymentMethod) {
      const paymentMethod = paymentMethods.find(
        (pm) => pm.name === values.method
      );
    }
    return isCustomPaymentMethod;
  };

  return (
    <div className='form-body h-100 w-100 overflow-hidden'>
      <div className='form-body__fields  w-100'>
        {values.method === 'cash' && isPaymentSelected && (
          <NewCash
            values={values}
            total={total}
            fmt={fmt}
            currencyCode={currencyCode}
            anotationMaxLimit={anotationMaxLimit}
          />
        )}

        {(values.method === 'debit' || values.method === 'credit') && (
          <NewCard
            form={form}
            values={values}
            total={total}
            annotationMaxLimit={anotationMaxLimit}
          />
        )}

        {values.method === 'combined' && (
          <NewCombined
            values={values}
            form={form}
            fmt={fmt}
            annotationMaxLimit={anotationMaxLimit}
          />
        )}

        {values.method === 'transfer' && (
          <NewTransfer
            values={values}
            total={total}
            annotationMaxLimit={anotationMaxLimit}
          />
        )}

        {values.method === 'nequi' && (
          <QRTransfer form={form} values={values} total={total} />
        )}

        {values.method === 'bancolombiaQR' && (
          <NewBancolombiaQR
            form={form}
            values={values}
            total={total}
            anotationMaxLimit={anotationMaxLimit}
            setExternalPaymentSuccess={setExternalPaymentSuccess}
          />
        )}

        {values.method === 'nequiPush' && activeNequiPush && (
          <NewPushNotification
            form={form}
            values={values}
            total={total}
            anotationMaxLimit={anotationMaxLimit}
            setExternalPaymentSuccess={setExternalPaymentSuccess}
          />
        )}

        {values.method === 'nequiPush' && !activeNequiPush && (
          <NewNequiQR
            form={form}
            values={values}
            total={total}
            anotationMaxLimit={anotationMaxLimit}
            setExternalPaymentSuccess={setExternalPaymentSuccess}
          />
        )}
        {checkCustomPaymentMethod() && isPaymentSelected ? (
          <CustomPaymentMethod
            form={form}
            values={values}
            total={total}
            annotationMaxLimit={anotationMaxLimit}
          />
        ) : (
          !includes(defaultMethods, values.method) &&
          isPaymentSelected && (
            <NewTransfer
              values={values}
              total={total}
              annotationMaxLimit={anotationMaxLimit}
            />
          )
        )}
      </div>
      {isPaymentSelected && (
        <>
          {!canCreditInvoice && !firstLoad && received !== null && (
            <div className='mt-4'>
              <CreditLimitNotification
                client={client}
                values={values}
                total={totalPrice}
                totalToCollect={totalToCollect}
                setCanCredit={setCanCredit}
              />
            </div>
          )}

          <div className='w-100 d-flex justify-content-end '>
            <NewChange values={values} total={totalPrice} />
          </div>
        </>
      )}
    </div>
  );
};

export default NewBasicData;
