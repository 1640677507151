import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash'
import BigNumber from 'bignumber.js';

import { Icon } from '@alegradev/smile-ui-react';

import { decimalPrecision, tipsSelector as tipsSettingsSelector } from '../../../../selectors/company';
import { currency } from '../../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../../selectors/currencies'
import { subtotal, discount, discSubtotal, taxes, total as totalSelector, tipSelector } from '../../../../selectors/activeRefund';
import { setTip } from '../../../../reducers/activeRefund';
import { useFormState } from 'react-final-form';
import { useFormat } from '../../../../hooks/useFormat';

const showTip = ({ document, devolutionType, tipsSettings }) => {
  if (devolutionType === 'combined') {
    return get(tipsSettings, 'enabled', false)
  }

  const documentHaveTip = !!get(document, 'additionalCharges[0]', false);
  return documentHaveTip
}

const Summary = ({openEditTip}) => {
  const { values } = useFormState();
  const selectedCurrency = useSelector(currency)
  const invoiceSubtotal = useSelector(subtotal);
  const invoiceDiscount = useSelector(discount);
  const invoiceDiscSubtotal = useSelector(discSubtotal);
  const invoiceTaxes = useSelector(taxes);
  const total = useSelector(totalSelector);
  const tips = useSelector(tipSelector);
  const tipsSettings = useSelector(tipsSettingsSelector)
  const { decimal, fmt } = useFormat(selectedCurrency);
  const devolutionType = get(values, 'refund.method.value');
  const dispatch = useDispatch();

  useEffect(() => {
    const invoiceHaveTip = !!get(values, 'refund.document.additionalCharges[0]', false);
    if (!invoiceHaveTip) {
      dispatch(setTip({ include: false }))
    } else {
      const additionalCharges = get(values, 'refund.document.additionalCharges[0]', null);
      if (additionalCharges) {
        const newTip = {
          include: true,
          type: get(additionalCharges, 'amount', 0) > 0 ? 'VALUE' : 'PERCENTAGE',
          value: get(additionalCharges, 'amount', 0),
          percentage: get(tipsSettings, 'suggestedValue', 10),
        }
        dispatch(setTip(newTip))
      }
    }
  }, [values?.refund?.document])

  return (
    <div className="refund-bottom__summary d-flex flex-column mx-4 mt-3">
      {(invoiceDiscount.gt(0) || !!invoiceTaxes.length) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('discount', 'descuento')}
          </div>
          <div className="text-capitalize-first text-muted number">
            -{invoiceDiscount.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceDiscSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceTaxes.map((tax, index) => (
        <div key={index} className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {tax.name}
          </div>
          <div className="text-capitalize-first text-muted number">
            {tax.value.toFormat(decimal, fmt)}
          </div>
        </div>
      ))}

      {
        showTip({document: get(values, 'refund.document', null), devolutionType, tipsSettings}) && <Tips openEditTip={openEditTip} tip={tips} subtotal={invoiceSubtotal} />
      }

      {new BigNumber(total).gt(0) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc" style={{ fontWeight: '600' }}>
            {I18n.get('totalToRefund', 'total a devolver')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {new BigNumber(total).toFormat(decimal, fmt)}
          </div>
        </div>
      )}
    </div>
  )
}

const Tips = ({ tip, subtotal, openEditTip }) => {
  const dispatch = useDispatch();
  const values = useFormState().values;
  const selectedCurrency = useSelector(currency)
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const tipsSettings = useSelector(tipsSettingsSelector)
  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol') 
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : '' 

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const tipValue = get(tip, "value", 0)
  const tipPercentage = get(tip, "percentage", 10)
  const tipType = get(tip, "type", 'PERCENTAGE')

  const addTip = () => {
    const newTip = {
      include: true,
      type: get(values, 'refund.document.additionalCharges[0].amount', 0) > 0 ? 'VALUE' : 'PERCENTAGE',
      value: get(values, 'refund.document.additionalCharges[0].amount', 0),
      percentage: get(tipsSettings, 'suggestedValue', 10),
    }

    dispatch(setTip(newTip))
  }

  return (
    <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
      {get(tip, "include", false) ?
        (<>
          <div className="text-capitalize-first desc d-flex align-items-center">
            {I18n.get('tips', 'Propinas')} { tipType === 'PERCENTAGE' && `(${tipPercentage}%)`}
            <div className='ml-2 edit-tips' onClick={() => openEditTip()}>
              <Icon icon='pencil' size='small'/>
            </div>
          </div>
          <div className="text-capitalize-first text-muted number">
            {
              tipType === 'VALUE' && new BigNumber(tipValue).toFormat(decimal, fmt)
            }
            { tipType === 'PERCENTAGE' && subtotal.times(tipPercentage).div(100).toFormat(decimal, fmt) }
          </div>
        </>)
        :
        (
          <button className='btn-add-tip' onClick={addTip}>
            <Icon icon='plus' extraClass="icon-primary" />{I18n.get('Agregar propina', 'Agregar propina')}
          </button>
        )
      }
    </div>
  )
}


export default Summary;
