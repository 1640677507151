import es from './es'

const dictionary = {
  ...es,
  "aboutPOS": "¿Cómo usar Alegra TPV?",
  "actualStation": "Sucursal actual",
  "actualShiftIsClosed": "Abre una nueva caja y continúa gestionando el efectivo de tu negocio",
  "addWarehouse": "Agregar almacén",
  "AlegraAccounting": "Gestión",
  "AlegraAccounting.description": "Reportes, control de inventario y más",
  "AlegraPOS": "TPV",
  "alegraWeekIsHere2": "Compra dos meses de tu plan favorito de Alegra TPV y recibe el",
  "AssociatePriceListsToTerminalsNotification.title": "¡Configura tu lista de precios favorita! 💡",
  "AssociatePriceListsToTerminalsNotification.body": "Puedes definir una lista de precios en tu sucursal para que venga seleccionada por defecto y ahorrar tiempo al vender.",
  "AssociatePriceListsToTerminalsNotification.action": "Ir a configurarla",
  "baseHelp": "Indica el dinero en efectivo con el que abres tu caja",
  "cashManagementExpenseHelp": "Selecciona la cuenta contable por defecto para el registro de tus gastos desde el TPV",
  "cashOperationsByShifts": "Movimientos de caja",
  "checkInInPOS": "Facturación en TPV",
  "choosePlanNote1": "(*) Tu plan de Alegra TPV dependerá de tus ingresos mensuales.",
  "closeShift": "Cerrar caja",
  "closeShiftError": "No pudimos cerrar la caja. Inténtalo de nuevo",
  "companySettingInfo": "Ingresa tu información y adapta Alegra TPV a tu negocio",
  "configureShifts": "Configurar cajas",
  "configureStationWarehouse": "Configurar almacén",
  "createItemSubmitError1Message":
    "Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en almacén de los que elijas.",
  "createWarehouse": "Crear almacén",
  "createWarehouseError": "Error en la creación del almacén",
  "currentPOSPlan": "Plan actual",
  "currentShift": "Caja en curso",
  "currentShiftDetails": "Detalles de la caja en curso",
  "currentShiftDetails.description": "Conoce los movimientos de efectivo de tu caja actual.",
  "defaultCashBankTutorial": "Esta cuenta de banco se asigna por defecto a cada sucursal para que tengas un mejor control del efectivo.",
  "disableShifts": "Deshabilitar caja",
  "discountCalculationHelTooltip.link": "https://ayuda.alegra.com/es/edita-tus-productos-mientras-vendes-con-alegra-tpv",
  "distributeInWarehouses": "Distribuir en almacenes",
  "editPaymentWarning": "El nuevo valor no se reflejará en los ingresos de la caja actual.",
  "emissionStatus": "Estado de emisión BATUZ",
  "enableShifts": "Habilitar caja",
  "generalPOSFeatures": "Funciones generales de TPV",
  "generalStationInfoTutorial": "Configura los datos de tu sucursal y la información necesaria para crear tus documentos",
  "getIntoOtherTerminal": "Ingresar a otra sucursal",
  "happyWeekSubtitle3": "de Alegra TPV y recibe el",
  "identification": "NIF/CIF",
  "incomeLimitNotification":
    "Has excedido el límite de ingresos mensuales de  tu plan actual, elige un nuevo plan para realizar esta acción y seguir creciendo con Alegra TPV.",
  "incomes": "Ventas",
  "indicateTheVariantQuantity": "Indica aquí las cantidades en tus almacenes",
  "initAppError": "Ups! El TPV no se ha podido cargar",
  "initAppOfflineDataErrorMessage": "Hay datos que no han sido sincronizados pertenecientes a otra sucursal. Si continúa podría perderlos.",
  "inventariableHelp": "Distribuye tus productos entre tus almacenes",
  // "invoicePrintFooter": "Generado en Alegra TPV - alegra.com/tvp",
  "inventoryWarehouses": "Almacenes de inventario",
  "itemCategoriesAreEmpty": "Las categorías que seleccionaste están vacías en el almacén de esta sucursal. 🔍",
  "itemCategoryIsEmpty": "La categoría {} está vacía en el almacén de esta sucursal. 🌪️",
  "itemCreatedSuccessfullyMessageAlter": "Ten en cuenta que no lo asociaste al almacén de tu sucursal actual y para venderlo debes ",
  "itemCreatedSuccessfullyMessageLink": "cambiar de sucursal o editar esta.",
  "itemVariantHelp": "Indica aquí las cantidades en tus almacenes",
  "knowTheFirstStepsInPOS": "Conoce los primeros pasos en TPV",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/movimientos-de-caja-alegra-tpv-espana",
  "manageShifts": "Administrar cajas",
  "manageStations": "gestionar sucursales",
  "makeSureYouHaveTheNecessaryPermissions": "Asegurate de tener los permisos necesarios para ingresar al TPV",
  "multicurrencyFieldHelp": "Genera tus facturas TPV en diferentes monedas",
  "myWarehouses": "mis almacenes",
  "newRegulationForPOS.description": "Desde el 1 de abril tus ventas en documento TPV que superen <strong>$235.325 antes de IVA</strong> deberán ir en factura electrónica.",
  "newStation": "Nueva sucursal",
  "newWarehouse": "Nuevo almacén",
  "noEligibleStation": "No hay sucursales para elegir",
  "noEligibleStationMessage": "Verifica que hay por lo menos una sucursal activa",
  "noShiftInQuery": "Selecciona un nuevo período de consulta que cuente con cajas cerradas.",
  "noShiftsConfigured": "Las cajas son intervalos de tiempo que sirven para administrar tus operaciones en tu compañía. Comienza a utilizarlos",
  "noClosedShiftsYet.payments": "Aquí conocerás el resumen de los métodos de pago utilizados por tus clientes en las cajas cerradas.",
  "noClosedShiftsYet.shifts": "En este espacio verás el comportamiento de tus ingresos y gastos incluidos en las cajas cerradas.",
  "noStationPermissionError": "¿Necesitas acceder a esta sucursal?",
  "noStationPermissionWithNameError": "¿Necesitas ingresar a la sucursal{}?",
  "noStationPermissionErrorMessage": "Solicita acceso a un perfil administrador o ingresa desde otra sucursal en la que tengas permiso.",
  "noStationPermissionErrorMessageEnd": "desde la configuración de las sucursales.",
  "numerationExpired.notification.title": "¡Se venció la fecha de tu numeración! 📅",
  "numerationExpired.notification.text": "Ten en cuenta que la numeración {} ya está vencida y a partir del 17 de julio no podrás usarla al vender.",
  "numerationMaxNumberReached.notification.title": "¡Se acabaron los consecutivos de tu numeración! 🚨",
  "numerationMaxNumberReached.notification.text": "Ten en cuenta que la numeración {} se agotó y a partir del 17 de julio no podrás usarla al vender.",
  "numerationsSettingTooltip": 'Administra desde "Alegra Gestión" las numeraciones que usas para facturar en tu negocio.',
  "onboardingWelcomeTitle": "¡Te damos la bienvenida a Alegra TPV! 😉",
  "onboardingWizardSubtitleStep1": "Dale play y descubre lo fácil que vas a gestionar tu negocio con Alegra TPV",
  "openShift": "Abrir caja",
  "openShiftError": "Error en la apertura de la caja",
  "openShiftMoreMessage": "Aprende más sobre las cajas",
  "openShiftMessage": "Con esta funcionalidad vas a tener una mejor gestión del efectivo en tu punto de venta.",
  "paymentsLoadError": "Error cargando las operaciones de efectivo de la sucursal",
  "paymentMethodHelp": "En Alegra TPV puedes crear facturas de contado, conoce como generar tus facturas a crédito en Alegra Contabilidad",
  "planLimitReachedDescription3": "Más almacenes para tu inventario",
  "planIncomeFeature": "Ingresos hasta {}{} al mes",
  "pos": "TPV",
  "posDocument": "Documento TPV",
  "POSSetting": "Funcionalidades TPV",
  "POSSettingTutorial": "Activa las funcionalidades que utilizarás en todas tus sucursales",
  "posSettingsSubtitles": "Aquí puedes configurar los datos que intervienen directamente con el TPV",
  "POSStations": "Sucursales TPV",
  "POSTutorial": "Tutorial TPV",
  "productDistributionHelp.mid": " cómo distribuir en diferentes almacenes",
  "refundsLoadError": "Error cargando las devoluciones de la sucursal",
  "refundAccountWarning": "Solo se descontarán del valor en caja de tu cierre de turno los valores asociados a la cuenta bancaria del Efectivo TPV.",
  "rememberCloseTheShift": "Recuerda cerrar la caja o desactivar la opción de controlar cajas para tener una mejor experiencia.",
  "saleTickets": "Documentos equivalentes TPV",
  "saveAndCreateNew": "Guardar y crear nuevo",
  "seeMore": "Saber más",
  "selectAStation": "Selecciona una sucursal",
  "selectAStationHeadline": "Selecciona la sucursal para ejecutar tus operaciones",
  "setStationWarehouse": "Configura un almacén para tu sucursal",
  "setStationWarehouseHelp": "Parece que se desactivó o eliminó el almacén que estaba asociado a tu sucursal, selecciona uno para seguir vendiendo.",
  "shifts": "Movimientos de caja",
  "shiftsField": "Movimientos de caja",
  "shiftSetting": "Configuración de cajas",
  "shiftSettingTutorial": "Elige el banco de apertura y de cierre de las cajas para esta sucursal. ",
  "shiftHistory": "Historial de caja",
  "shiftStatistics": "Informes de caja",
  "shiftsLoadError": "Error cargando el historial de cajas de la sucursal",
  "shiftOpenSuccessfully": "¡Caja abierta! ✨",
  "shiftOpenSuccessfullyMessage": "Tu caja se abrió con éxito.",
  "shiftClosedSuccessfully": "¡Caja cerrada! ✨",
  "shiftClosedSuccessfullyMessage": "Ya estás teniendo el control del efectivo en tu punto de venta.",
  "shiftIsOpenToMuchTime":
    "Tienes una caja abierta hace más de una semana. Te recomendamos cerrarlo para controlar tus ingresos o desactivar esta opción desde la configuración de tu negocio.",
  "shiftNumber": "Número de caja",
  "shiftsStatisticsHelpText": "Conoce el detalle de tus cajas cerradas en el período de tiempo que elijas.",
  "shiftsStatisticsInfo": "Conoce la información de tus ingresos y egresos en las cajas cerradas.",
  "station": "Sucursal",
  "stationActivated": "Sucursal activada con éxito",
  "stationCreatedSuccessfullyMessage": "La sucursal {} ya está disponible para que gestiones las ventas de tu negocio.",
  "stationDeactivated": "Sucursal desactivada con éxito",
  "stationDeleted": "Sucursal eliminada con éxito",
  "stationDeletedError": "Error eliminando sucursal",
  "stationName": "Nombre de tu sucursal",
  "stations": "Sucursales",
  "stationSetting": "Configuración de sucursal",
  "stationSettingSubtitle": "Configura los datos de tu sucursal y la información necesaria para facturar.",
  "stationSettingTitle": "Sucursal actual",
  "stationsForYourPointOfSale": "Sucursales para tus puntos de venta",
  "stationsLoadError": "Sucedió un error cargando las sucursales",
  "stationsSettingSubtitle": "Crea y administra las sucursales que usas para la gestión de tus puntos de venta.",
  "stationsSettingTitle": "Gestiona tus sucursales TPV",
  "stationStatusError": "Error cambiando estado de sucursal",
  "stationUpdated": "Sucursal actualizada",
  "stationWarehouseDeletedError": "Solo puedes eliminar almacenes que no estén asociados a una sucursal.",
  "stationWarehouseStatusError": "Solo puedes desactivar almacenes que no estén asociados a una sucursal.",
  "subscriptionIndications": "Con Alegra TPV vendes en segundos y haces crecer tu negocio. Conoce a continuación los detalles de tu suscripción:",
  "taxesSettingTooltip": 'Configura desde "Alegra Gestión" los impuestos que necesitas para facturar en tu punto de venta.',
  "thereWasAnErrorLoadingShiftValues": "Hubo un error cargando los valores de la caja.",
  "totalOfShiftMovements": "Total movimientos al cierre",
  "updateStationError": "Error al cambiar los datos de la sucursal",
  "userAssociationTutorial": "elige los usuarios que tendrán acceso al uso de esta sucursal",
  "updateWarehouse": "Actualizar almacén",
  "updateWarehouseError": "Error al cambiar los datos del almacén",
  "userNotAllowed.warehouses.add": "Te hace falta el permiso para agregar almacenes",
  "userNotAllowed.warehouses.delete": "Te hace falta el permiso para eliminar almacenes",
  "userNotAllowed.warehouses.edit": "Te hace falta el permiso para editar almacenes",
  "userNotAllowed.pos-station.edit": "No tienes permisos suficientes para editar la información de la sucursal",
  "userNotAllowed.pos-station.index": "No tienes permiso para ver las sucursales",
  "userNotAllowed.pos-station.view": "No tienes permisos para ver detalle de sucursales",
  "userNotAllowed.pos-cashier.close": "No tienes permisos para cerrar cajas",
  "userNotAllowed.pos-cashier.open": "No tienes permisos para abrir cajas",
  "userNotAllowed.pos-cashier.view": "No tienes permiso para ver los cierres de caja anteriores",
  "userNotAllowed.contacts.view":
    "No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el TPV correctamente.",
  "videoIntroductionPOS": "Video introductorio TPV",
  "warehouse": "Almacén",
  "warehouses": "Almacenes",
  "warehouseActivated": "almacén activado con éxito",
  "warehouseChangeError": "No tienes los permisos para ver productos de otros almacenes.",
  "warehouseChangeInfo": "Cambia de almacén para visualizar los productos presentes en cada una.",
  "warehouseCreatedSuccessfullyMessage": "El almacén {} ya está disponible para que distribuyas tu inventario.",
  "warehouseDeactivated": "almacén desactivado con éxito",
  "warehouseDeleted": "almacén eliminado con éxito",
  "warehouseDeletedError": "error eliminando almacén",
  "warehouseQuantity": "Cantidad en Almacén",
  "warehouseSetting": "Configuración de almacén",
  "warehousesLoadError": "Sucedió un error cargando los almacenes",
  "warehousesSettingSubtitle": "Administra los almacenes de tu negocio",
  "warehousesInventorySubtitle": "Crea almacenes para gestionar tu inventario en diferentes lugares de almacenamiento y distribución.",
  "warehouseNoDataText": "Crea tu primer almacén en segundos. 🙌",
  "warehouseStatusError": "error cambiando estado del almacén",
  "warehouseUpdated": "almacén actualizado con éxito",
  "welcomeToAlegraPOS": "¡Bienvenido a Alegra TPV!",
  "welcomeToYourPOSSystem": "¡Bienvenido a tu sistema TPV!",
  "youDontHaveCreatedWarehouses": "No has creado tu primer almacén, créala aquí.",
  "youDontHaveCreatedStations": "No has creado tu primera sucursal, créala aquí",
  "yourPOSSystem": "tu sistema TPV",
  "youMustSynchronizeBeforeCloseShift": "Debes sincronizar tu información antes de poder cerrar la caja",
  "offlineNotificationTitle": "¡Estás usando Alegra TPV sin internet!",
  "offlineNotification": "Puedes seguir facturando y tus ventas se guardarán al recuperar la conexión.",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Genera tus facturas en segundos",
  "AlegraAccounting.new": "Gestión",
  "AlegraAccounting.description.new": "Factura, administra y gestiona tu stock",
  "AlegraPOS.new": "TPV",
  "AlegraPOS.description.new": "Agiliza tus ventas y controla tu efectivo",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "terminal_summary": "Resumen de sucursal",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Gestión para que los subas de manera masiva.",
  "AlegraInvoicing.new": "Facturación",
  "AlegraInvoicing.description.new": "Crea tus facturas en segundos",
  "electronic.saleTicket": "Factura Simplificada",
  "electronic.invoice": "Factura Ordinaria",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Gestión controlas tus ingresos y gastos con un clic, y obtienes reportes administrativos y de ventas en tiempo real.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.invoice.supTitle": "Ahorra tiempo con",
  "solutionModal.invoice.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.invoice.buttonSecondaryText": "Más información",
  "solutionModal.invoice.description": "Genera en segundos tus facturas con el software de Facturación más veloz de España. Sistema 100% adaptado a la Agencia Tributaria.",
  "solutionModal.invoice.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el Sistema Punto de Venta más veloz del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "city": "Municipio",
  "contact.ext.city": "ciudad",
  "numerationModal.amount.total": "La venta supera €{}",
  "regulation.message": "Según Hacienda, las ventas que superen este valor deben crearse con una numeración de factura ordinaria.",
  "continue.sale": "Continuar venta",
  "change.numeration": "Cambiar numeración",
  "regulation.message.help": "Según Hacienda, las ventas que superen este valor deben ir con una numeración de factura ordinaria, aprende {}",
  "how.to.create.numeration": "cómo crear numeraciones",
  "invoicePrintFooter": "Generado en Alegra TPV - alegra.com/TPV",
  "taxableIncome": "Base imponible",
  "discountedBasis": "Base con descuento",
  "unitCostSimplified": "Coste inicial",
  "invoiceOfSale": "Ticket de venta",
  "invoiceModalTitle.saleTicket": "Ticket",
  "invoiceModalTitle.saleTicketSaved": "Ticket guardado",
  "unitCost": "Coste inicial por unidad",
  "contactSupportDescription": "Ahora encontrarás todas tus opciones de ayuda reunidas en un solo lugar y disponibles 24/7. ¡Descubre tu nuevo centro de soporte!",
  "contactSupportTitleNewUsers": "Explora tus canales de soporte 🧑‍💻",
  "contactSupportDescriptionNewUsers": "Descubre dónde encontrar asistencia cuando la necesites. Explora nuestros canales de soporte disponibles las 24 horas, todos los días.",
  "exploreNowNewUsers": "Ver nuevo centro de soporte",
  "manageYourAccountDescription": "Encuentra todas las configuraciones comunes de tus soluciones de Alegra en un mismo lugar",
  "shift.permission.message": "Te hace falta el permiso para {{action}} caja",
  "xmlDocument": "Documento XML de la factura",
  "mobilePhone": "Móvil",
  "cellPhone": "Móvil",
  "ticketBai": "TicketBAI",
  "warehouseChangeMessage": "Puedes cambiar de almacén para revisar los productos de cada uno.",
  "warehouseChangeErrorNew": 'Te falta el permiso de "ver detalle" en almacenes para revisar las demás.',
  "goAppDesktopTitle": "Accede a Alegra TPV",
  "goAppDesktopDescription": "Selecciona cómo prefieres abrir tu punto de venta.",
  "goAppDesktopCtaOne": "Abrir versión de ordenador",
  "goAppDesktopCtaTwo": "Continuar en el navegador",
}

export default dictionary;