import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { country as countrySelector, currency as currencySelector, printSettings, tipsSelector } from '../../../selectors/company'
import { numeration as numerationSelector } from '../../../selectors/activeInvoice';
import { stationInvoiceNumeration as stationNumerationSelector } from '../../../selectors/app';
import Company from './Company';
import Logo from './Logo';
import Info from './Info';
import Client from './Client';
import Items from './Items';
import Payments from './Payments';
import Electronic from './Electronic';
import Complement from './Complement';
import LastRow from './LastRow';
import CustomPhrase from './CustomPhrase';
import ImageOrQr from './ImageOrQr';
import Title from './Title';
import { isAfterDateToProduction } from './utilities';
import { get } from 'lodash';
import MulticurrencyPayments from './MulticurrencyPayments';
import Trm from './Trm';
import { COUNTRIES } from '../../../utils/enums/countries';
import Stamp from './Stamp';
import ProvisionalAdvice from './provisional/ProvionalAdvice';
import ProvisionalQR from './provisional/ProvisionalQR';
import ProvisionalPayment from './provisional/ProvisionalPayment';
import ProvisionalClient from './provisional/ProvisionalClient';

const renderProvisionalTemplate = (template, props) => {
  const currentDefaultCurrency = useSelector(currencySelector);
  const currentSellCurrencyCode = get(props.invoice, "currency.code", get(currentDefaultCurrency, "code"));
  const isMulticurrencySell = (currentSellCurrencyCode !== get(currentDefaultCurrency, "code") && props.country === 'colombia');
  props.isMulticurrencySell = isMulticurrencySell;
  props.currentDefaultCurrency = currentDefaultCurrency;

  return (
    <>
      <Logo {...props} />
      <Company {...props} />
      <ProvisionalClient {...props} />
      <Items {...props} />
      <ProvisionalPayment {...props} />
      <ProvisionalAdvice {...props} />
      <ProvisionalQR {...props} />
    </>
  )
}

const renderTemplate = (template, props) => {
  const currentDefaultCurrency = useSelector(currencySelector);
  const numeration = !!props.invoice.numberTemplate
    ? props.invoice.numberTemplate
    : !!props.invoice.numeration
      ? props.invoice.numeration
      : props.numeration
  const isAfterDate = isAfterDateToProduction(props.invoice, "2023-04-25");
  const country = props.country;
  const isElectronic = get(props.invoice, "stamp", null);
  const currentSellCurrencyCode = get(props.invoice, "currency.code", get(currentDefaultCurrency, "code"));
  const isMulticurrencySell = (currentSellCurrencyCode !== get(currentDefaultCurrency, "code") && props.country === 'colombia');
  props.isMulticurrencySell = isMulticurrencySell;
  props.currentDefaultCurrency = currentDefaultCurrency;

  if (props.country === 'republicaDominicana' && ["E31", "E32"].includes(numeration.prefix)) {
    return (
      <>
        <Company {...props} />
        <Logo {...props} />
        <Info {...props} />
        <Client {...props} />
        <Items {...props} />
        {isMulticurrencySell ? <MulticurrencyPayments {...props} /> : <Payments {...props} />}
        {isMulticurrencySell && (<Trm {...props} />)}
        <Electronic {...props} />
        <CustomPhrase {...props} />
        <LastRow {...props} />
      </>
    )
  }

  switch (template) {
    case 'modern':

      return (
        <>
          <Title {...props} />
          {
            (country === 'panama' && isAfterDate && !!isElectronic) ? null : (<Logo {...props} />)
          }
          <Company {...props} />
          {
            (country === 'panama' && isAfterDate && !!isElectronic) ? (<Logo {...props} />) : null
          }
          <Client {...props} />
          <Info {...props} />
          <Items {...props} />
          {isMulticurrencySell ? <MulticurrencyPayments {...props} /> : <Payments {...props} />}
          {isMulticurrencySell && (<Trm {...props} />)}
          <Electronic {...props} />
          <Complement {...props} />
          <CustomPhrase {...props} />
          <ImageOrQr {...props} />
          <LastRow {...props} />
        </>
      )
    case 'simple':
      return (
        <>
          <Title {...props} />
          {
            (country === 'panama' && isAfterDate && !!isElectronic) ? null : (<Logo {...props} />)
          }
          {
            country !== COUNTRIES.COLOMBIA && <Electronic {...props} />
          }
          <Company {...props} />
          {
            (country === 'panama' && isAfterDate && !!isElectronic) ? (<Logo {...props} />) : null
          }
          <Client {...props} />
          <Info {...props} />
          <Items {...props} />
          {isMulticurrencySell ? <MulticurrencyPayments {...props} /> : <Payments {...props} />}
          {isMulticurrencySell && (<Trm {...props} />)}
          {country === COUNTRIES.COLOMBIA && <Electronic {...props} />}
          <Complement {...props} />
          <CustomPhrase {...props} />
          <ImageOrQr {...props} />
          <LastRow {...props} />
        </>
      )
    default:
      return (
        <>
          <Title {...props} />
          <Company {...props} />
          <Logo {...props} />
          <Info {...props} />
          <Client {...props} />
          <Items {...props} />
          {isMulticurrencySell ? <MulticurrencyPayments {...props} /> : <Payments {...props} />}
          {isMulticurrencySell && (<Trm {...props} />)}
          <Electronic {...props} />
          <Complement {...props} />
          <CustomPhrase {...props} />
          <ImageOrQr {...props} />
          {country === COUNTRIES.MEXICO && <Stamp {...props} />}
          <LastRow {...props} />

        </>
      )
  }
}

const Print = ({ setting, invoice, preview, pendingInvoice, provisional = false }) => {
  const companySetting = useSelector(printSettings);
  const country = useSelector(countrySelector);
  const numeration = useSelector(numerationSelector);
  const stationNumeration = useSelector(stationNumerationSelector);
  if (!setting)
    setting = companySetting;

  return (
    <div
      id="print-invoice"
      className={`print-${setting.format} ${!!preview ? 'd-flex' : 'd-none'} flex-column overflow-hidden`}
      style={{ paddingLeft: `${setting.leftMargin}mm`, paddingRight: `${setting.rightMargin}mm` }}
    >
      {
        !provisional
          ? renderTemplate(setting.template, { setting, invoice, pendingInvoice, country, numeration: !!numeration ? numeration : stationNumeration })
          : renderProvisionalTemplate(setting.template, { setting, invoice, pendingInvoice, country })
      }
    </div>
  )
}

Print.propTypes = {
  setting: PropTypes.shape({
    template: PropTypes.oneOf(['classic', 'modern', 'simple']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    description: PropTypes.bool,
    unitPrice: PropTypes.bool,
    logo: PropTypes.bool,
    format: PropTypes.oneOf(['57', '80']),
    leftMargin: PropTypes.number,
    rightMargin: PropTypes.number
  }),
  invoice: PropTypes.object,
  preview: PropTypes.bool,
  pendingInvoice: PropTypes.bool,
}

export default Print;

